import * as Dialog from '@radix-ui/react-dialog';
import { useForm } from 'react-hook-form';
import { cn } from '@/lib/utils';
import {
  editorRootElementRef$,
  imageDialogState$,
  imageUploadHandler$,
  saveImage$,
  closeImageDialog$,
  useCellValues,
  usePublisher,
} from '@mdxeditor/editor';
import { useState, useCallback } from 'react';
import { X } from 'lucide-react';

interface FormValues {
  file?: FileList;
  src?: string;
  title?: string;
}

export function ImageDialog() {
  const [state, editorRootElementRef, imageUploadHandler] = useCellValues(
    imageDialogState$,
    editorRootElementRef$,
    imageUploadHandler$,
  );

  const saveImage = usePublisher(saveImage$);
  const closeImageDialog = usePublisher(closeImageDialog$);

  const { register, handleSubmit, reset, setValue } = useForm<FormValues>({
    values: state.type === 'editing' ? state.initialValues : {},
  });

  const [preview, setPreview] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.types.includes('Files')) {
      setIsDragging(true);
    }
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const file = e.dataTransfer.files[0];
      if (file && file.type.startsWith('image/')) {
        const dt = new DataTransfer();
        dt.items.add(file);
        setValue('file', dt.files);
        setPreview(URL.createObjectURL(file));
      }
    },
    [setValue],
  );

  const clearPreview = useCallback(() => {
    if (preview) {
      URL.revokeObjectURL(preview);
    }
    setPreview(null);
    setValue('file', undefined);
  }, [preview, setValue]);

  return (
    <Dialog.Root
      open={state.type !== 'inactive'}
      onOpenChange={(open) => {
        if (!open) {
          closeImageDialog();
          reset({ src: '', title: '' });
          clearPreview();
        }
      }}
    >
      <Dialog.Portal container={editorRootElementRef?.current}>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" />
        <Dialog.Content
          className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[90vw] max-w-[450px] max-h-[85vh] bg-white rounded-lg p-6 shadow-lg"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <Dialog.Title className="text-lg font-semibold mb-4">Insert Image</Dialog.Title>

          <form
            onSubmit={(e) => {
              void handleSubmit(saveImage)(e);
              reset({ src: '', title: '' });
              clearPreview();
              e.preventDefault();
              e.stopPropagation();
            }}
            className="space-y-4"
          >
            {imageUploadHandler === null ? (
              <input type="hidden" accept="image/*" {...register('file')} />
            ) : preview ? (
              <div className="relative w-[150px] h-[150px] mx-auto">
                <img
                  src={preview}
                  alt="Preview"
                  className="w-full h-full object-contain rounded-md"
                />
                <button
                  type="button"
                  onClick={clearPreview}
                  className="absolute -top-2 -right-2 p-1 bg-white rounded-full shadow-md hover:bg-gray-100"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <div
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                className={cn(
                  'relative border-2 border-dashed rounded-lg p-4 transition-colors',
                  isDragging ? 'border-primary bg-primary/5' : 'border-gray-300',
                )}
              >
                <label htmlFor="file" className="block text-sm font-medium mb-2">
                  Choose an image from your device
                </label>
                <input
                  type="file"
                  accept="image/*"
                  {...register('file', {
                    onChange: (e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        setPreview(URL.createObjectURL(file));
                      }
                    },
                  })}
                  className="w-full text-sm file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-primary file:text-primary-foreground hover:file:bg-primary/90"
                />
              </div>
            )}

            <div className={cn('space-y-2', preview && 'hidden')}>
              <label htmlFor="src" className="block text-sm font-medium">
                {imageUploadHandler !== null ? 'Or insert image from URL' : 'Enter image URL'}
              </label>
              <input
                type="text"
                {...register('src')}
                className="w-full rounded-md border border-input px-3 py-2 text-sm shadow-sm"
                placeholder="Enter an image URL"
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="title" className="block text-sm font-medium">
                Image Title
              </label>
              <input
                type="text"
                {...register('title')}
                className="w-full rounded-md border border-input px-3 py-2 text-sm shadow-sm"
              />
            </div>

            <div className="flex justify-end gap-2 mt-6">
              <button
                type="submit"
                title="Apply"
                className={cn(
                  'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors',
                  'bg-primary text-primary-foreground shadow hover:bg-primary/90',
                  'h-9 px-4 py-2',
                )}
              >
                Apply
              </button>

              <Dialog.Close asChild>
                <button
                  type="reset"
                  title="Cancel"
                  className={cn(
                    'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors',
                    'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
                    'h-9 px-4 py-2',
                  )}
                >
                  Cancel
                </button>
              </Dialog.Close>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
