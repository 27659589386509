import { fetchASNOverview, fetchTechnologyDataApi } from '@/api/assets.api';
import {
  ASNData,
  Asset,
  AssetDetails,
  AssetDetailsWithDetails,
  AssetWithDetails,
} from '@/types/api/response/assets';
import { useGetAssetsList, usePrefetchAssetsList } from '@/v2/queries/assets';
import { useQuery } from '@tanstack/react-query';
import { parseAsArrayOf, parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { useMemo } from 'react';

interface AssetQueries {
  assets: AssetWithDetails[];
  error: Error | null;
  page: number;
  totalPages: number;
  totalCount: number;
  handlePageChange: (newPage: number) => void;
  asnData: ASNData[];
  selectedAsns: string[];
  handleSelectAsns: (values: number[]) => void;
  mappedAsns: { value: string; label: string }[];
  handleAsnChange: (values: string[]) => void;
  technologiesSuggestions: string[];
  isPending: boolean;
  mappedSelectedAsns: string[];
  setSearchParamsState: (state: {
    filterBy: string;
    filterType: string;
    value: string | null;
  }) => void;
  prefetchChangePage: (page: number) => void;
  domainname: string;
  domainnameFilterOperator: 'equals' | 'contains' | 'startswith';
  setDomainname: (value: string) => void;
  setDomainnameFilterOperator: (value: 'equals' | 'contains' | 'startswith') => void;
  technology: string;
  technologyFilterOperator: 'include' | 'exclude';
  setTechnology: (value: string) => void;
  setTechnologyFilterOperator: (value: 'include' | 'exclude') => void;
  createdAt: string;
  createdAtFilterOperator: string;
  setCreatedAt: (value: string) => void;
  setCreatedAtFilterOperator: (value: string) => void;
  domainSource: string;
  domainSourceFilterOperator: string;
  setDomainSource: (value: string) => void;
  setDomainSourceFilterOperator: (value: string) => void;
  lastScanned: string;
  lastScannedFilterOperator: string;
  setLastScanned: (value: string) => void;
  setLastScannedFilterOperator: (value: string) => void;
  ipaddress: string;
  ipaddressFilterOperator: string;
  setIpaddress: (value: string) => void;
  setIpaddressFilterOperator: (value: string) => void;
  technologyChangeWindow: string;
  technologyChangeWindowFilterOperator: string;
  setTechnologyChangeWindow: (value: string) => void;
  setTechnologyChangeWindowFilterOperator: (value: string) => void;
  asn: string[];
  setAsn: (value: string[]) => void;
  sortField: string;
  setSortField: (value: string) => void;
  sortDirection: string;
  setSortDirection: (value: string) => void;
}

export const mapAssetDetailsData = (asset: AssetDetails): AssetDetailsWithDetails => {
  const dnsRecord = asset.dns_record;
  const hostname = asset.domain.domainname;
  let ipAddress = 'None';
  let cname = null;
  let asnDetails = { asn: 'N/A', iprange: 'N/A', owner: 'N/A' };

  let ipSource = null;
  if (dnsRecord) {
    cname = dnsRecord.cname;
    ipSource = dnsRecord.ipaddress?.[0];
  } else if (asset.alternative_dns_resolution) {
    ipSource = asset.alternative_dns_resolution;
  } else if (asset.ipaddressv4) {
    ipSource = asset.ipaddressv4;
  }

  if (ipSource) {
    ipAddress = ipSource.ipaddress;
    if (ipSource.asn) {
      asnDetails = {
        asn: ipSource.asn.asn,
        iprange: ipSource.asn.iprange,
        owner: ipSource.asn.owner,
      };
    }
  }

  return {
    ...asset,
    hostname,
    ipAddress,
    cname,
    asnDetails,
  };
};

export const mapAssetData = (asset: Asset): AssetWithDetails => {
  const dnsRecord = asset.dns_record;
  const hostname = asset.domainname;
  let ipAddress = 'None';
  let cname = null;
  let asnDetails = { asn: 'N/A', iprange: 'N/A', owner: 'N/A' };

  let ipSource = null;
  if (dnsRecord) {
    cname = dnsRecord.cname;
    ipSource = dnsRecord.ipaddress?.[0];
  } else if (asset.alternative_dns_resolution) {
    ipSource = asset.alternative_dns_resolution;
  } else if (asset.ipaddressv4) {
    ipSource = asset.ipaddressv4;
  }

  if (ipSource) {
    ipAddress = ipSource.ipaddress;
    if (ipSource.asn) {
      asnDetails = {
        asn: ipSource.asn.asn,
        iprange: ipSource.asn.iprange,
        owner: ipSource.asn.owner,
      };
    }
  }

  return {
    ...asset,
    hostname,
    ipAddress,
    cname,
    asnDetails,
  };
};

export const useAssetQueries = (): AssetQueries => {
  const endpoint = '/assets';

  const prefetchAssetsList = usePrefetchAssetsList();

  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [domainname, setDomainname] = useQueryState('domainname', parseAsString.withDefault(''));
  const [domainnameFilterOperator, setDomainnameFilterOperator] = useQueryState(
    'domainname_filter_operator',
    parseAsString.withDefault('startswith'),
  );
  const [asn, setAsn] = useQueryState('asn', parseAsArrayOf(parseAsString).withDefault([]));
  const [technology, setTechnology] = useQueryState('technology', parseAsString.withDefault(''));
  const [technologyFilterOperator, setTechnologyFilterOperator] = useQueryState(
    'technology_filter_operator',
    parseAsString.withDefault('include'),
  );
  const [createdAt, setCreatedAt] = useQueryState('created_at', parseAsString.withDefault(''));
  const [createdAtFilterOperator, setCreatedAtFilterOperator] = useQueryState(
    'created_at_filter_operator',
    parseAsString.withDefault('greater_than'),
  );
  const [domainSource, setDomainSource] = useQueryState(
    'domain_source',
    parseAsString.withDefault(''),
  );
  const [domainSourceFilterOperator, setDomainSourceFilterOperator] = useQueryState(
    'domain_source_filter_operator',
    parseAsString.withDefault('include'),
  );
  const [lastScanned, setLastScanned] = useQueryState(
    'last_scanned',
    parseAsString.withDefault(''),
  );
  const [lastScannedFilterOperator, setLastScannedFilterOperator] = useQueryState(
    'last_scanned_filter_operator',
    parseAsString.withDefault('greater_than'),
  );
  const [ipaddress, setIpaddress] = useQueryState('ipaddress', parseAsString.withDefault(''));
  const [ipaddressFilterOperator, setIpaddressFilterOperator] = useQueryState(
    'ipaddress_filter_operator',
    parseAsString.withDefault('include'),
  );
  const [technologyChangeWindow, setTechnologyChangeWindow] = useQueryState(
    'technology_change_window',
    parseAsString.withDefault(''),
  );
  const [technologyChangeWindowFilterOperator, setTechnologyChangeWindowFilterOperator] =
    useQueryState(
      'technology_change_window_filter_operator',
      parseAsString.withDefault('greater_than'),
    );
  const [sortField, setSortField] = useQueryState('sort_by', parseAsString.withDefault(''));
  const [sortDirection, setSortDirection] = useQueryState('order', parseAsString.withDefault(''));
  const { data, isPending, error } = useGetAssetsList({
    page: page,
    domainname: domainname,
    domainname_filter_operator: domainnameFilterOperator as 'equals' | 'contains' | 'startswith',
    asn: asn.join(','),
    technology: technology,
    technology_filter_operator: technologyFilterOperator as 'include' | 'exclude',
    created_at: createdAt,
    created_at_filter_operator: createdAtFilterOperator as 'equals' | 'contains' | 'startswith',
    domain_source: domainSource,
    domain_source_filter_operator: domainSourceFilterOperator as
      | 'equals'
      | 'contains'
      | 'startswith',
    last_scanned: lastScanned,
    last_scanned_filter_operator: lastScannedFilterOperator as 'equals' | 'contains' | 'startswith',
    ipaddress: ipaddress,
    ipaddress_filter_operator: ipaddressFilterOperator as 'equals' | 'contains' | 'startswith',
    technology_change_window: technologyChangeWindow,
    technology_change_window_filter_operator: technologyChangeWindowFilterOperator as
      | 'equals'
      | 'contains'
      | 'startswith',
    sort_by: sortField,
    order_by: sortDirection,
  });

  const asnQuery = useQuery({
    queryKey: ['asnOverview', endpoint],
    queryFn: () => fetchASNOverview('', endpoint),
  });

  const prefetchChangePage = (nextPage: number) => {
    prefetchAssetsList({
      page: nextPage,
      domainname: domainname,
      domainname_filter_operator: domainnameFilterOperator as 'equals' | 'contains' | 'startswith',
      asn: asn.join(','),
      technology: technology,
      technology_filter_operator: technologyFilterOperator as 'include' | 'exclude',
      created_at: createdAt,
      created_at_filter_operator: createdAtFilterOperator as 'equals' | 'contains' | 'startswith',
      domain_source: domainSource,
      domain_source_filter_operator: domainSourceFilterOperator as
        | 'equals'
        | 'contains'
        | 'startswith',
      last_scanned: lastScanned,
      last_scanned_filter_operator: lastScannedFilterOperator as
        | 'equals'
        | 'contains'
        | 'startswith',
      ipaddress: ipaddress,
      ipaddress_filter_operator: ipaddressFilterOperator as 'equals' | 'contains' | 'startswith',
      technology_change_window: technologyChangeWindow,
      technology_change_window_filter_operator: technologyChangeWindowFilterOperator as
        | 'equals'
        | 'contains'
        | 'startswith',
    });
  };

  const technologiesQuery = useQuery({
    queryKey: ['technologiesSuggestions'],
    queryFn: () => fetchTechnologyDataApi(),
  });

  const mappedAsns = useMemo(
    () =>
      asnQuery.data
        ? asnQuery.data.map((asn) => ({
            value: asn.asn ? asn.asn.toString() : 'Unknown',
            label: `${asn.owner ?? 'Unknown'} (ASN: ${asn.asn ?? 'Unknown'}) - ${asn.count} entries`,
          }))
        : [],
    [asnQuery.data],
  );

  const handleAsnChange = (values: string[]) => {
    const numericValues = values.filter((val) => val !== '').map(Number);
    handleSelectAsns(numericValues);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSelectAsns = (values: number[]) => {
    if (values.length > 0) {
      setAsn(values.map(String));
    } else {
      setAsn([]);
    }
    setPage(1);
  };

  const mappedSelectedAsns = asn ? asn.map((asn) => asn.toString()) : [];

  const handleGenericChange = <T>(value: T, setValue: (value: T) => void) => {
    setValue(value);
    setPage(1);
  };

  return {
    assets: data?.assets || [],
    error: error || null,
    page,
    totalPages: data?.totalPages || 0,
    totalCount: data?.totalCount || 0,
    handlePageChange,
    asnData: asnQuery.data || [],
    selectedAsns: asn,
    handleSelectAsns,
    mappedAsns,
    handleAsnChange,
    technologiesSuggestions: technologiesQuery.data || [],
    isPending: isPending || false,
    mappedSelectedAsns,
    setSearchParamsState: () => {},
    prefetchChangePage,
    setDomainname: (value: string) => handleGenericChange(value, setDomainname),
    setDomainnameFilterOperator: (value: 'equals' | 'contains' | 'startswith') =>
      handleGenericChange(value, setDomainnameFilterOperator),
    setAsn: (value: string[]) => handleGenericChange(value, setAsn),
    setTechnology: (value: string) => handleGenericChange(value, setTechnology),
    setTechnologyFilterOperator: (value: 'include' | 'exclude') =>
      handleGenericChange(value, setTechnologyFilterOperator),
    setCreatedAt: (value: string) => handleGenericChange(value, setCreatedAt),
    setCreatedAtFilterOperator: (value: 'equals' | 'contains' | 'startswith') =>
      handleGenericChange(value, setCreatedAtFilterOperator),
    setDomainSource: (value: string) => handleGenericChange(value, setDomainSource),
    setDomainSourceFilterOperator: (value: 'equals' | 'contains' | 'startswith') =>
      handleGenericChange(value, setDomainSourceFilterOperator),
    setLastScanned: (value: string) => handleGenericChange(value, setLastScanned),
    setLastScannedFilterOperator: (value: 'equals' | 'contains' | 'startswith') =>
      handleGenericChange(value, setLastScannedFilterOperator),
    setIpaddress: (value: string) => handleGenericChange(value, setIpaddress),
    setIpaddressFilterOperator: (value: 'equals' | 'contains' | 'startswith') =>
      handleGenericChange(value, setIpaddressFilterOperator),
    setTechnologyChangeWindow: (value: string) =>
      handleGenericChange(value, setTechnologyChangeWindow),
    setTechnologyChangeWindowFilterOperator: (value: 'equals' | 'contains' | 'startswith') =>
      handleGenericChange(value, setTechnologyChangeWindowFilterOperator),
    domainname,
    domainnameFilterOperator: domainnameFilterOperator as 'equals' | 'contains' | 'startswith',
    ipaddress,
    ipaddressFilterOperator,
    technology,
    technologyFilterOperator: technologyFilterOperator as 'include' | 'exclude',
    createdAt,
    createdAtFilterOperator,
    lastScanned,
    lastScannedFilterOperator,
    domainSource,
    domainSourceFilterOperator,
    technologyChangeWindow,
    technologyChangeWindowFilterOperator,
    asn,
    sortField,
    setSortField,
    sortDirection,
    setSortDirection,
  };
};
