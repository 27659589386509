import { useState, useEffect } from 'react';
import { v4 } from 'uuid';

export interface ImageMapEntry {
  filename: string;
  blob: Blob;
}

export type ImageMap = Record<string, ImageMapEntry>;

export const buildNormalizedFilename = (filename: string) =>
  `${v4()}.${filename.split('.').pop() || ''}`;

export const buildFormData = (
  fieldName: string,
  imageMap: ImageMap,
  existingFormData?: FormData,
) => {
  const formData = existingFormData || new FormData();

  // Add the images to the form data
  Object.entries(imageMap).forEach(([, entry]) => {
    formData.append(fieldName, entry.blob, entry.filename);
  });

  // Add/update the _meta.blob_mapping field
  const blobMapping: Record<string, string> = {};
  Object.entries(imageMap).forEach(([url, entry]) => {
    blobMapping[url] = entry.filename;
  });

  // Get existing meta if it exists
  let meta = {};
  try {
    const existingMeta = formData.get('_meta');
    if (existingMeta) {
      meta = JSON.parse(existingMeta as string);
    }
  } catch {
    // Handle malformed metadata gracefully
  }

  // Merge the blob mapping with existing meta
  formData.set(
    '_meta',
    JSON.stringify({
      ...meta,
      blob_mapping: blobMapping,
    }),
  );

  return formData;
};

export const useAttachedImages = (onChange?: (imageMap: ImageMap) => void) => {
  const [imageMap, setImageMap] = useState<ImageMap>({});

  useEffect(() => {
    onChange?.(imageMap);
  }, [imageMap, onChange]);

  const resetImages = () => {
    setImageMap({});
  };

  const addImage = async (file: File) => {
    // Create a copy of the file's data
    const blob = file.slice(0, file.size, file.type);
    const url = URL.createObjectURL(blob);

    // Map the Blob URL to the file
    setImageMap((prev) => ({
      ...prev,
      [url]: { filename: buildNormalizedFilename(file.name), blob },
    }));

    return url;
  };

  const removeImage = (url: string) => {
    // Remove the image from the map
    setImageMap((prev) => Object.fromEntries(Object.entries(prev).filter(([key]) => key !== url)));

    // Revoke the Blob URL
    URL.revokeObjectURL(url);
  };

  return { resetImages, imageMap, addImage, removeImage };
};
