import { useState } from 'react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import AutoCompleteSearchBar from './AutoCompleteSearchBar';
import MarkdownEditor from '@/components/Markdown/MarkdownEditor';
import { Minimize2, Maximize2, X, ChevronDown } from 'lucide-react';
import './Notes.css';
import { useCreateNewNote } from '@/v2/queries/notes';
import { useUpdateNote } from '@/v2/queries/notes';
import { useAttachedImages } from '@/hooks/useAttachedImages';

interface NoteCreatorProps {
  headerContent?: string;
  initialContent?: string;
  noteId?: string | null;
  assetId?: string | null;
  onAfterSave?: (assetId: string | null, content: string, noteId: string | null) => void;
  onCloseAction?: () => void;
}

export default function NoteCreator({
  headerContent,
  initialContent,
  noteId,
  assetId,
  onAfterSave,
  onCloseAction,
}: NoteCreatorProps) {
  const [noteContent, setNoteContent] = useState(initialContent || '');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [wasFullScreenBeforeMinimize, setWasFullScreenBeforeMinimize] = useState(false);
  const [selectedAssetId, setSelectedAsset] = useState<string | null>(null);

  const { addImage, resetImages, imageMap } = useAttachedImages();

  const handleImageUpload = async (file: File) => {
    const url = await addImage(file);
    return url;
  };

  const currentAssetId = selectedAssetId ?? assetId;

  const afterSave = (id: string) => {
    setNoteContent('');
    resetImages();
    onAfterSave?.(currentAssetId, noteContent, id);
  };

  const {
    mutate: createNewNote,
    // isPending: isCreatingNewNote,
    // error: createNewNoteError,
  } = useCreateNewNote({
    onSuccess: afterSave,
  });

  const {
    mutate: updateNote,
    // isPending: isUpdatingNote,
    // error: updateNoteError,
  } = useUpdateNote({
    onSuccess: afterSave,
  });

  const handleSave = async () => {
    if (noteId) {
      updateNote({
        noteId,
        text: noteContent,
        imageMap,
      });
    } else {
      createNewNote({
        params: {
          httpAsset: currentAssetId,
          noteText: noteContent,
        },
        imageMap,
      });
    }
  };

  const handleAssetSelect = (assetSelected: string) => {
    setSelectedAsset(assetSelected);
  };

  const handleAssetSearch = (query: string) => {
    // Implement search functionality if needed
    // eslint-disable-next-line no-console
    console.log('Search query:', query);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const toggleMinimize = () => {
    if (!isMinimized) {
      setWasFullScreenBeforeMinimize(isFullScreen);
      setIsFullScreen(false);
    } else {
      setIsFullScreen(wasFullScreenBeforeMinimize);
    }
    setIsMinimized(!isMinimized);
  };

  const handleModalClick = () => {
    if (isMinimized) {
      toggleMinimize();
    }
  };

  return (
    <div
      className={cn(
        'fixed z-20 bg-background rounded-lg shadow-lg border',
        'flex flex-col overflow-hidden',
        !isFullScreen &&
          !isMinimized &&
          'bottom-4 right-4 h-[48vh] w-[64vw] min-h-[480px] min-w-[640px]',
        isFullScreen && 'inset-0 rounded-none',
        isMinimized && 'bottom-4 right-4 h-12 w-64',
      )}
      onClick={handleModalClick}
    >
      <div className="flex items-center justify-between p-2 border-b bg-muted/40">
        {headerContent ? (
          <span className="text-xs font-medium truncate">{headerContent}</span>
        ) : (
          <AutoCompleteSearchBar
            placeholder="Start typing to find an asset"
            onSelect={handleAssetSelect}
            onSearch={handleAssetSearch}
            color="black"
          />
        )}
        <div className="flex items-center gap-1">
          {!isMinimized && (
            <Button variant="ghost" size="icon" onClick={toggleMinimize} className="h-6 w-6">
              <ChevronDown className="h-4 w-4" />
            </Button>
          )}
          {!isMinimized && (
            <Button variant="ghost" size="icon" onClick={toggleFullScreen} className="h-6 w-6">
              {isFullScreen ? <Minimize2 className="h-4 w-4" /> : <Maximize2 className="h-4 w-4" />}
            </Button>
          )}
          <Button variant="ghost" size="icon" onClick={onCloseAction} className="h-6 w-6">
            <X className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <div className="flex-1 flex flex-col min-h-0">
        <MarkdownEditor
          markdown={noteContent}
          onChange={setNoteContent}
          imageUploadHandler={handleImageUpload}
          placeholder="Start writing..."
          className="flex-1 min-h-0"
          noRoundedBorder
          disallowFullScreen
        />
      </div>
      <div className="flex items-center justify-end gap-2 p-2 border-t bg-muted/40">
        <Button
          variant="default"
          size="sm"
          onClick={handleSave}
          className="bg-[#f05941] hover:bg-[#f05941]/90"
        >
          Save and Publish
        </Button>
      </div>
    </div>
  );
}
