import { useAuth } from '@/Helpers/AuthContext';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { toast } from '@/hooks/use-toast';
import type { UserSettings } from '@/types/api/response/user';
import { useUpdateUserSettings } from '@/v2/queries/users';
import { getTimezones } from '@/v2/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

const eventPreferenceSchema = z.object({
  channels: z.array(z.string()),
  daily_report: z.boolean(),
  weekly_report: z.boolean(),
});

const userSettingsSchema = z.object({
  timezone: z.string(),
  notifications: z.object({
    channels: z.array(z.string()),
    email: z.string(),
    max_notifications_per_day: z.number(),
    event_preferences: z.object({
      new_issue: eventPreferenceSchema,
      service_change: eventPreferenceSchema,
    }),
  }),
});

type UserSettingsFormData = z.infer<typeof userSettingsSchema>;

const DEFAULT_EVENT_PREFERENCES = {
  channels: [],
  daily_report: false,
  weekly_report: false,
};

export default function UserSettings() {
  const { userData } = useAuth();
  const { mutate: updateUserSettings } = useUpdateUserSettings();

  const form = useForm<UserSettingsFormData>({
    resolver: zodResolver(userSettingsSchema),
    defaultValues: {
      timezone: userData?.settings?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      notifications: {
        channels: userData?.settings?.notifications?.channels || [],
        email: userData?.settings?.notifications?.email || userData?.email,
        max_notifications_per_day:
          userData?.settings?.notifications?.max_notifications_per_day || 100,
        event_preferences: {
          new_issue:
            userData?.settings?.notifications?.event_preferences?.new_issue ||
            DEFAULT_EVENT_PREFERENCES,
          service_change:
            userData?.settings?.notifications?.event_preferences?.service_change ||
            DEFAULT_EVENT_PREFERENCES,
        },
      },
    },
  });

  const handleSubmit = async (data: UserSettingsFormData) => {
    try {
      updateUserSettings(data as UserSettings);
      toast({
        title: 'Settings updated',
        description: 'Your settings have been successfully saved.',
        className: 'bg-green-50 border-green-200 text-green-700',
        duration: 3000,
      });
    } catch (error) {
      console.error('Failed to save settings:', error);
      toast({
        title: 'Error',
        description: 'Failed to save settings. Please try again.',
        className: 'bg-red-50 border-red-200 text-red-700',
        duration: 3000,
      });
    }
  };

  const notificationsEnabled = form.watch('notifications.channels').length > 0;

  return (
    <div className="container max-w-2xl py-10 mx-auto px-4">
      <h1 className="text-2xl font-bold mb-8">User Settings</h1>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="timezone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Timezone</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    form.handleSubmit(handleSubmit)();
                  }}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select your timezone" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {getTimezones().map((tz) => (
                      <SelectItem key={tz.value} value={tz.value}>
                        {tz.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />

          <div className="space-y-6">
            <FormField
              control={form.control}
              name="notifications.channels"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Notifications</FormLabel>
                    <FormDescription>Enable notifications</FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value?.length > 0}
                      onCheckedChange={(checked) => {
                        const newValue = checked ? ['email'] : [];
                        field.onChange(newValue);
                        if (!checked) {
                          // Reset all event preferences when notifications are disabled
                          form.setValue(
                            'notifications.event_preferences.new_issue',
                            DEFAULT_EVENT_PREFERENCES,
                          );
                          form.setValue(
                            'notifications.event_preferences.service_change',
                            DEFAULT_EVENT_PREFERENCES,
                          );
                        }
                        form.handleSubmit(handleSubmit)();
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            {notificationsEnabled && (
              <>
                <NotificationSection
                  form={form}
                  title="New Issues"
                  description="Notifications for new issues"
                  basePath="notifications.event_preferences.new_issue"
                  onSubmit={handleSubmit}
                />

                <NotificationSection
                  form={form}
                  title="Service Changes"
                  description="Notifications for service changes"
                  basePath="notifications.event_preferences.service_change"
                  onSubmit={handleSubmit}
                />
              </>
            )}
          </div>
        </form>
      </Form>
    </div>
  );
}

type NotificationSectionProps = {
  form: UseFormReturn<UserSettingsFormData>;
  title: string;
  description: string;
  basePath:
    | 'notifications.event_preferences.new_issue'
    | 'notifications.event_preferences.service_change';
  onSubmit: (data: UserSettingsFormData) => Promise<void>;
};

const NotificationSection = ({
  form,
  title,
  description,
  basePath,
  onSubmit,
}: NotificationSectionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const channels = form.watch(`${basePath}.channels`);
  const dailyReport = form.watch(`${basePath}.daily_report`);
  const weeklyReport = form.watch(`${basePath}.weekly_report`);

  const activeChannels = [
    ...(channels || []),
    ...(dailyReport ? ['daily digest'] : []),
    ...(weeklyReport ? ['weekly digest'] : []),
  ];

  const handleSave = () => {
    setIsOpen(false);
    form.handleSubmit(onSubmit)();
  };

  return (
    <div className="rounded-lg border p-4 space-y-4">
      <div className="space-y-4">
        <div className="space-y-0.5">
          <FormLabel className="text-base">{title}</FormLabel>
          <FormDescription>{description}</FormDescription>
        </div>

        <div className="relative">
          <Button variant="outline" onClick={() => setIsOpen(!isOpen)} type="button">
            Notify me: {activeChannels.length ? activeChannels.join(', ') : 'None'}
          </Button>

          {isOpen && (
            <div className="absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
              <div className="p-4 space-y-4">
                <FormField
                  control={form.control}
                  name={`${basePath}.channels`}
                  render={({ field }) => (
                    <FormItem className="flex items-center space-x-2">
                      <FormControl>
                        <Checkbox
                          checked={field.value?.includes('email')}
                          onCheckedChange={(checked) => {
                            const newChannels = checked
                              ? [...(field.value || []), 'email']
                              : (field.value || []).filter((c) => c !== 'email');
                            field.onChange(newChannels);

                            // If email is unchecked, reset daily and weekly reports
                            if (!checked) {
                              form.setValue(`${basePath}.daily_report`, false);
                              form.setValue(`${basePath}.weekly_report`, false);
                            }
                          }}
                        />
                      </FormControl>
                      <FormLabel className="text-sm font-normal">Email</FormLabel>
                    </FormItem>
                  )}
                />

                <div className="border-t my-2" />

                <FormField
                  control={form.control}
                  name={`${basePath}.daily_report`}
                  render={({ field }) => (
                    <FormItem className="flex items-center space-x-2">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked);
                          }}
                        />
                      </FormControl>
                      <FormLabel className="text-sm font-normal">Daily digest</FormLabel>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`${basePath}.weekly_report`}
                  render={({ field }) => (
                    <FormItem className="flex items-center space-x-2">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked);
                          }}
                        />
                      </FormControl>
                      <FormLabel className="text-sm font-normal">Weekly digest</FormLabel>
                    </FormItem>
                  )}
                />

                <div className="pt-2 flex justify-end space-x-2">
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button type="button" size="sm" onClick={handleSave}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
