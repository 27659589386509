import { cn } from '@/lib/utils';
import { ExternalLink, Globe, MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';

interface SpecularLinkProps {
  href?: string;
  children: React.ReactNode;
  className?: string;
  title?: string;
  resourceType?: 'HTTPAsset' | 'IPAddressV4' | 'Domain';
  id?: string;
}

export function SpecularLink({
  href,
  children,
  className,
  title,
  resourceType,
  id,
}: SpecularLinkProps) {
  const linkClasses = cn(
    'inline-flex items-center gap-1 hover:underline',
    href
      ? 'text-blue-600 dark:text-blue-400'
      : 'text-gray-900 dark:text-gray-100 bg-gray-100/50 dark:bg-gray-800/50 px-1.5 py-0.5 rounded',
    className,
  );

  if (href) {
    return (
      <a
        href={href}
        className={linkClasses}
        target="_blank"
        rel="noopener noreferrer"
        title={title}
      >
        {children}
        <ExternalLink className="h-3 w-3" />
      </a>
    );
  }

  const linkTo = (() => {
    if (!resourceType || !id) return null;

    switch (resourceType) {
      case 'HTTPAsset':
        return `/assets/${id}`;
      case 'IPAddressV4':
        return `/ip/${id}`;
      case 'Domain':
        return `/domains/${id}`;
      default:
        return null;
    }
  })();

  if (!linkTo) return null;

  return (
    <Link to={linkTo} className={linkClasses} title={title}>
      {resourceType === 'HTTPAsset' && <Globe className="h-4 w-4 mr-1" />}
      {resourceType === 'IPAddressV4' && <MapPin className="h-4 w-4 mr-1" />}
      {resourceType === 'Domain' && <Globe className="h-4 w-4 mr-1" />}
      {children}
    </Link>
  );
}
