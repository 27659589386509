import { Copy, Download, WrapText } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import { CODE_BLOCK_LANGUAGES, CodeBlockLanguage } from '../constants';
import { useCodeBlock } from '../context/CodeBlockContext';

interface CodeToolbarProps {
  code: string;
  language?: string;
}

export function CodeToolbar({ code, language }: CodeToolbarProps) {
  const { toast } = useToast();
  const { isWrapEnabled, toggleWrap } = useCodeBlock();

  const handleCopy = () => {
    navigator.clipboard.writeText(code);

    toast({
      title: 'Copied to clipboard',
    });
  };

  const handleDownload = () => {
    const blob = new Blob([code], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `code${language ? `.${language}` : '.txt'}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    toast({
      title: 'Downloaded code',
    });
  };

  const displayLanguage =
    language && language in CODE_BLOCK_LANGUAGES
      ? CODE_BLOCK_LANGUAGES[language as CodeBlockLanguage]
      : language;

  return (
    <div className="flex items-center justify-between gap-1 px-3 py-1.5 bg-slate-700/50 backdrop-blur border-t border-slate-600/50 rounded-b-lg">
      {displayLanguage && (
        <span className="text-xs text-gray-400 font-mono">{displayLanguage}</span>
      )}
      <div className="flex items-center gap-1">
        <button
          className={`p-1.5 text-gray-300 hover:text-white hover:bg-slate-600/30 rounded-md transition-colors ${
            isWrapEnabled ? 'text-white bg-slate-600/70' : ''
          }`}
          onClick={toggleWrap}
          title={`${isWrapEnabled ? 'Disable' : 'Enable'} line wrapping`}
        >
          <WrapText className="h-4 w-4" />
        </button>
        <button
          className="p-1.5 text-gray-300 hover:text-white hover:bg-slate-600/30 rounded-md transition-colors"
          onClick={handleCopy}
          title="Copy code"
        >
          <Copy className="h-4 w-4" />
        </button>
        <button
          className="p-1.5 text-gray-300 hover:text-white hover:bg-slate-600/30 rounded-md transition-colors"
          onClick={handleDownload}
          title="Download code"
        >
          <Download className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
}
