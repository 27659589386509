import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useEffect, useRef } from 'react';
import { AvailableFilter } from '../UniversalFilter/UniversalFilter';

interface NormalSelectFilterProps {
  filter: AvailableFilter;
  onUpdate: (filter: AvailableFilter, value: string) => void;
}

export default function NormalSelectFilter({ filter, onUpdate }: NormalSelectFilterProps) {
  const selectRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (selectRef.current) {
        selectRef.current.focus();
      }
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Select value={filter.state as string} onValueChange={(value) => onUpdate(filter, value)}>
      <SelectTrigger ref={selectRef}>
        <SelectValue placeholder={filter.placeholder} />
      </SelectTrigger>
      <SelectContent>
        {filter.options?.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
