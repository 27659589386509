import {
  deleteIssue,
  getIssue,
  getIssues,
  getIssuesParams,
  updateIssue,
  UpdateIssuePayload,
} from '@/api/issues.api';
import { Issue, IssuesResponse } from '@/types/api/response/issues';
import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
export const getIssuesQuery = (params: getIssuesParams = {}) =>
  queryOptions({
    queryKey: ['issues', params],
    queryFn: () => getIssues(params),
  });

export const useGetIssue = (issueId: number) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['issue', issueId],
    queryFn: () => {
      // First check if we have the individual issue cached
      const cachedIssue = queryClient.getQueryData<Issue>(['issue', issueId]);
      if (cachedIssue) return cachedIssue;

      // Then check if we can find it in any of the issues list caches
      const issuesQueries = queryClient.getQueriesData<IssuesResponse>({ queryKey: ['issues'] });
      for (const [, data] of issuesQueries) {
        const foundIssue = data?.entries.find((issue) => issue.issue_number === issueId);
        if (foundIssue) return foundIssue;
      }

      // If not found in cache, fetch it
      return getIssue(issueId);
    },
  });
};

export const useUpdateIssueMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ issueId, updates }: { issueId: number; updates: UpdateIssuePayload }) =>
      updateIssue(issueId, updates),
    onMutate: async ({ issueId, updates }) => {
      // Cancel any outgoing refetches to avoid overwriting our optimistic update
      await queryClient.cancelQueries({ queryKey: ['issue', issueId] });
      await queryClient.cancelQueries({ queryKey: ['issues'] });

      // Snapshot the previous values
      const previousIssue = queryClient.getQueryData(['issue', issueId]);
      const previousIssues = queryClient.getQueriesData({ queryKey: ['issues'] });

      // Optimistically update the issue cache
      const optimisticIssue = { id: issueId, ...updates };
      queryClient.setQueryData(['issue', issueId], optimisticIssue);

      // Optimistically update any issue lists
      queryClient.setQueriesData(
        { queryKey: ['issues'] },
        (oldData: IssuesResponse | undefined) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            entries: oldData.entries.map((issue) =>
              issue.issue_number === issueId ? { ...issue, ...updates } : issue,
            ),
          };
        },
      );

      // Return the snapshotted values
      return { previousIssue, previousIssues };
    },
    onError: (err, { issueId }, context) => {
      // Revert back to the previous values if there's an error
      if (context?.previousIssue) {
        queryClient.setQueryData(['issue', issueId], context.previousIssue);
      }
      if (context?.previousIssues) {
        context.previousIssues.forEach(([queryKey, value]) => {
          queryClient.setQueryData(queryKey, value);
        });
      }
    },
    onSuccess: (updatedIssue: Issue, { issueId }) => {
      // Update with the actual server response
      queryClient.setQueryData(['issue', issueId], updatedIssue);
      queryClient.setQueriesData(
        { queryKey: ['issues'] },
        (oldData: IssuesResponse | undefined) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            entries: oldData.entries.map((issue) =>
              issue.issue_number === issueId ? { ...issue, ...updatedIssue } : issue,
            ),
          };
        },
      );
    },
  });
};

export const useInvalidateIssuesQuery = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: ['issues'] });
};

export const useDeleteIssueMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteIssue,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['issues'] }),
  });
};
