import { AddAssetResponse, addAssetFailedResponse } from '@/types/api/response/assets';
import { ServiceResponse } from '@/types/api/response/services';
import { ApiResponse, fetchWrapper } from '../lib/fetchWrapper';

type ServiceParams = {
  serviceversion?: string;
  ports?: string;
  services?: string;
  ipaddress?: string;
  state?: string;
  cpe?: string;
  page?: number;
  asn?: string;
};

export const getServices = async (params: ServiceParams): Promise<ApiResponse<ServiceResponse>> => {
  const response = await fetchWrapper.get<ServiceResponse>('/portservice', { params });
  return response;
};

export const addIPAddress = async (data: {
  ipaddress: string[];
  notes: string;
}): Promise<ApiResponse<void>> => {
  const response = await fetchWrapper.post<void>('/ipaddress/', data);
  return response;
};

export const addAsset = async (data: {
  entries: string[];
  notes: string;
}): Promise<ApiResponse<AddAssetResponse, addAssetFailedResponse>> => {
  const response = await fetchWrapper.post<AddAssetResponse, addAssetFailedResponse>(
    '/assets',
    data,
  );
  return response;
};
