import { fetchWrapper } from '@/lib/fetchWrapper';
import { MessageHistoryResponse, MessageResponse } from '@/types/api/response/message';
import { z } from 'zod';

export const CreateMessageBody = z.object({
  chatId: z.string(),
  content: z.string(),
});

export type CreateMessageBodyType = z.infer<typeof CreateMessageBody>;

export const createMessage = async (message: CreateMessageBodyType): Promise<MessageResponse> => {
  const response = await fetchWrapper.post(`/ai-assistant`, message);
  return response.data;
};

//POST /ai-assistant/chat_id/reset - to reset the conversation
export const resetConversation = async (chatId: string): Promise<void> => {
  await fetchWrapper.post(`/ai-assistant/${chatId}/reset`, {});
  return;
};

//GET /ai-assistant/chat_id/history - to fetch chat history
export const fetchChatHistory = async (chatId: string): Promise<MessageHistoryResponse> => {
  const response = await fetchWrapper.get<MessageHistoryResponse>(
    `/ai-assistant/${chatId}/history`,
  );
  return response.data;
};

export const streamMessage = async (message: CreateMessageBodyType): Promise<MessageResponse> => {
  const response = await fetchWrapper.stream('/ai-assistant', {
    chatId: message.chatId,
    content: message.content,
    stream_response: true,
  });

  const reader = response.body?.getReader();
  if (!reader) throw new Error('No reader available');

  let content = '';
  let isDone = false;
  while (!isDone) {
    const { done, value } = await reader.read();
    isDone = done;
    if (isDone) break;

    // Convert the chunk to text
    const chunk = new TextDecoder().decode(value);
    content += chunk;

    // Dispatch a custom event with the current content
    window.dispatchEvent(
      new CustomEvent('ai-message-chunk', {
        detail: { content, chatId: message.chatId },
      }),
    );
  }

  return {
    id: crypto.randomUUID(),
    chatId: message.chatId,
    role: 'assistant',
    content,
    timestamp: new Date(),
    status: 'sent',
  };
};

export const mockCreateMessage = async (
  message: CreateMessageBodyType,
): Promise<MessageResponse> => {
  try {
    // Validate the input using Zod schema
    const validatedMessage = await CreateMessageBody.parseAsync(message);

    // Simulate network delay (random between 500ms and 2000ms)
    const delay = Math.floor(Math.random() * 1500) + 500;
    await new Promise((resolve) => setTimeout(resolve, delay));

    // Create response with validated data
    const response: MessageResponse = {
      id: crypto.randomUUID(),
      content: validatedMessage.content,
      role: 'assistant',
      timestamp: new Date(),
      status: 'sent',
    };

    return response;
  } catch (error) {
    if (error instanceof z.ZodError) {
      throw new Error(`Validation error: ${error.errors.map((e) => e.message).join(', ')}`);
    }
    throw error;
  }
};
