import { z } from 'zod';
import { fetchWrapper } from '../lib/fetchWrapper';
import {
  ASNData,
  AssetDetails,
  AssetHistoryResponse,
  AssetResponse,
  AssetURLOverviewResponse,
  AssetURLResponse,
  Suggestion,
} from '../types/api/response/assets';

const endpoint = '/assets';
const v2Endpoint = '/v2_assets';
const v3Endpoint = '/v3_assets';

export const fetchASNOverview = async (base_query = '', lookup = '/assets') => {
  try {
    const queryURLParams = new URLSearchParams();
    if (base_query === '/vulnoverview') {
      queryURLParams.append('base_query', 'vulnoverview');
    } else if (base_query === '/vulnscanoverview') {
      queryURLParams.append('base_query', 'vulnscanoverview');
    }

    const api_endpoint = lookup === '/assets' ? `/assets/asnoverview` : `/domain/asnoverview`;

    const response = await fetchWrapper.get<ASNData[]>(api_endpoint, {
      params: Object.fromEntries(queryURLParams),
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching history data:', error);
  }
};

export const fetchAssetsParamsSchema = z.object({
  domainname: z.string().optional(),
  domainname_filter_operator: z.enum(['equals', 'contains', 'startswith']).optional(),
  technology: z.string().optional(),
  technology_filter_operator: z.enum(['include', 'exclude']).optional(),
  created_at: z
    .string()
    .regex(/^\d+[dh]$/)
    .optional(),
  created_at_filter_operator: z.string().optional(),
  domain_source: z.string().optional(),
  domain_source_filter_operator: z.string().optional(),
  last_scanned: z.string().optional(),
  last_scanned_filter_operator: z.string().optional(),
  ipaddress: z.string().optional(),
  ipaddress_filter_operator: z.string().optional(),
  technology_change_window: z.string().optional(),
  technology_change_window_filter_operator: z.string().optional(),
  asn: z.string().optional(),
  hostname: z.string().optional(),
  search: z.string().optional(),
  limit: z.number().int().positive().optional(),
  page: z.number().int().positive(),
  sort_by: z.string().optional(),
  order_by: z.string().optional(),
});

export type FetchAssetsParams = z.infer<typeof fetchAssetsParamsSchema>;

export const fetchAssetsApi = async (params: FetchAssetsParams): Promise<AssetResponse> => {
  const response = await fetchWrapper.get<AssetResponse>(`${v3Endpoint}`, {
    params,
  });
  return response.data;
};

export const fetchSingleAssetApi = async (id: number): Promise<AssetResponse> => {
  const response = await fetchWrapper.get<AssetResponse>(`${v2Endpoint}/${id}`);

  return response.data;
};

export const fetchAssetHistoryApi = async (id: number): Promise<AssetHistoryResponse> => {
  const response = await fetchWrapper.get<AssetHistoryResponse>(`${endpoint}/${id}/history`);
  return response.data;
};

export const fetchAssetDetailsApi = async (id: string): Promise<AssetDetails> => {
  const response = await fetchWrapper.get<AssetDetails>(`${v2Endpoint}/${id}`);
  return response.data;
};

export type FetchAssetURLsParams = {
  page: number;
  technology: string;
  extension: string;
  statusCode: string;
};

export const fetchAssetURLsApi = async (
  id: number,
  page: number,
  technology: string,
  extension: string,
  statusCode: string,
): Promise<AssetURLResponse> => {
  const response = await fetchWrapper.get<AssetURLResponse>(`${endpoint}/${id}/urls`, {
    params: { page, limit: 100, technology, extension, statusCode },
  });
  return response.data;
};

export const fetchAssetURLsOverviewApi = async (id: number): Promise<AssetURLOverviewResponse> => {
  const encoded_id = encodeURIComponent(id);
  const response = await fetchWrapper.get<AssetURLOverviewResponse>(
    `${endpoint}/${encoded_id}/urloverview`,
    {},
  );
  return response.data;
};

export const fetchTechnologyDataApi = async (): Promise<string[]> => {
  try {
    const response = await fetchWrapper.get<string[]>('/technology-suggestions');
    return response.data;
  } catch (error) {
    console.error('Error fetching technology data:', error);
  }
};

export const getAssetSuggestionsApi = async (
  startingWith: string,
  filterType = 'startswith',
  onlyVulnerabilities = false,
): Promise<Suggestion[]> => {
  try {
    const params = new URLSearchParams();
    if (filterType === 'startswith') {
      params.set('startswith', startingWith);
    } else if (filterType === 'contains') {
      params.set('contains', startingWith);
    } else {
      throw new Error('Invalid filter type');
    }

    if (onlyVulnerabilities) {
      params.set('only_vulnerable', 'true');
    }

    const response = await fetchWrapper.get<Suggestion[]>('/assets/search', {
      params: Object.fromEntries(params),
    });
    return response.data;
  } catch (error) {
    console.error('Error searching assets:', error);
    return [];
  }
};

export const triggerAIScanApi = async (id: string): Promise<void> => {
  await fetchWrapper.post<void>(`/cve-analyzer`, {
    cve_id: id,
  });
};
