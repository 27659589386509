import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useAuth } from '@/Helpers/AuthContext';
import { toast } from '@/hooks/use-toast';
import NoteCreator from '@/Notes/NoteCreator';
import { Suggestion } from '@/types/api/response/assets';
import { Note } from '@/types/api/response/notes';
import AssetsFinderAutocomplete from '@/v2/components/AssetsFinderAutocomplete/AssetsFindetAutocomplete';
import ThreePointsSettings from '@/v2/icons/Global/threePointsSettings';
import { getAssetSuggestionsQuery } from '@/v2/queries/assets';
import { getUserNotesQuery, useDeleteNote } from '@/v2/queries/notes';
import { useQuery } from '@tanstack/react-query';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Calendar, Clock, Download, PencilLine, StickyNote, Trash } from 'lucide-react';
import { useState } from 'react';
import MarkdownViewer from '@/components/Markdown/MarkdownViewer';

const Notes = () => {
  const { userData } = useAuth();
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState<string>('startswith');
  const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion | null>(null);
  const [freeSearchValue, setFreeSearchValue] = useState<string>('');
  const [notesFilter, setNotesFilter] = useState<string[]>([]);
  const [showNoteCreator, setShowNoteCreator] = useState<boolean>(false);
  const [editNote, setEditNote] = useState<Note | null>(null);

  const {
    mutate: deleteNote,
    // isPending: isDeletingNote,
    // error: deleteNoteError,
  } = useDeleteNote({
    onSuccess: () => setShowNoteCreator(false),
  });

  const onAfterSave = () => {
    setShowNoteCreator(false);
  };

  const { data } = useQuery(
    getUserNotesQuery({
      verbose: true,
      httpAsset: selectedSuggestion?.id,
      search: freeSearchValue,
      filter: notesFilter,
    }),
  );

  const { data: suggestions } = useQuery(
    getAssetSuggestionsQuery({
      assetFilter: searchFilter,
      assetFilterValue: search,
      onlyVulnerabilities: false,
      enabled: search.length > 1,
    }),
  );

  const handleSelectedSuggestion = (suggestion: Suggestion) => {
    setSelectedSuggestion(suggestion);
    setSearch(suggestion.domain.domainname);
  };

  const handleClearSuggestion = () => {
    setSelectedSuggestion(null);
    setSearch('');
  };

  const handleFreeSearchChange = (value: string) => {
    setFreeSearchValue(value);
  };

  const handleClearFreeSearch = () => {
    setFreeSearchValue('');
  };

  const handleNotesFilterChange = (value: string, checked: boolean | string) => {
    if (checked) {
      setNotesFilter([...notesFilter, value]);
    } else {
      setNotesFilter(notesFilter.filter((v) => v !== value));
    }
  };

  const handleOpenNoteCreator = (note: Note) => {
    setEditNote(note);
    setShowNoteCreator(true);
  };

  const handleCloseNoteCreator = () => {
    setEditNote(null);
    setShowNoteCreator(false);
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDeleteNote = (noteId: string) => {
    deleteNote(noteId);
    toast({
      title: 'Note deleted successfully',
      description: 'Note deleted successfully',
    });
  };

  const preloadImages = (element: HTMLElement) => {
    const images: HTMLImageElement[] = Array.from(element.getElementsByTagName('img'));
    const loadPromises = images.map(
      (image) =>
        new Promise((resolve, reject) => {
          if (image.complete && image.naturalHeight !== 0) {
            resolve(void 0);
          } else {
            image.onload = resolve;
            image.onerror = reject;
          }
        }),
    );
    return Promise.all(loadPromises);
  };

  const handleDownloadNote = async (note: Note) => {
    const markdownPreviewElement: HTMLElement = document.querySelector(
      `#markdown-preview-${note.id}`,
    ) as HTMLElement;

    if (markdownPreviewElement) {
      await preloadImages(markdownPreviewElement);

      const canvas = await html2canvas(markdownPreviewElement, { useCORS: true });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`note-${note.id}.pdf`);
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between mb-4">
        <div className="flex gap-4">
          <div className="flex gap-2">
            <Input
              placeholder="Search notes"
              onChange={(e) => handleFreeSearchChange(e.target.value)}
              value={freeSearchValue}
              onClear={() => handleClearFreeSearch()}
              showClearButton={freeSearchValue.length > 0}
            />
            <AssetsFinderAutocomplete
              assetFilter={searchFilter}
              handleAssetFilterChange={setSearchFilter}
              assetFilterValue={search}
              handleAssetFilterValueChange={setSearch}
              suggestions={suggestions}
              showSuggestions={!selectedSuggestion}
              handleSuggestionClick={handleSelectedSuggestion}
              onClear={handleClearSuggestion}
              searchingBy="Asset"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="terms"
              checked={notesFilter.includes('myNotes')}
              onCheckedChange={(checked) => handleNotesFilterChange('myNotes', checked)}
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              My Notes Only
            </label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="terms"
              checked={notesFilter.includes('orgNotes')}
              onCheckedChange={(checked) => handleNotesFilterChange('orgNotes', checked)}
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Test Customer Notes
            </label>
          </div>
        </div>
        <Button
          className="bg-v2-orange text-white flex items-center gap-2"
          variant="outline"
          onClick={() => handleOpenNoteCreator(null)}
        >
          <StickyNote className="h-4 w-4  " />
          Create Note
        </Button>
      </div>
      <ScrollArea className="h-[calc(100vh-200px)]">
        <div className="flex flex-col gap-8 bg-[#FAF7F3] p-4 rounded-lg">
          {data?.map((note, index) => (
            <>
              <div className="flex flex-col gap-4" key={note.id}>
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-medium text-muted-foreground">User:</span>
                    <span className="text-sm font-semibold">{note.user}</span>
                  </div>
                  <div className="flex items-center gap-4">
                    <span
                      className="text-sm font-semibold flex items-center gap-[2px]"
                      title={`Created: ${new Date(note.created).toLocaleDateString()} at ${new Date(note.last_modified).toLocaleTimeString()}`}
                    >
                      <Calendar className="w-4 h-4" />
                      {new Date(note.created).toLocaleDateString()}
                    </span>
                    <span
                      className="text-sm font-semibold flex items-center gap-[2px] text-muted-foreground"
                      title={`Last modified: ${new Date(note.last_modified).toLocaleDateString()} at ${new Date(note.last_modified).toLocaleTimeString()}`}
                    >
                      <Clock className="w-4 h-4" />
                      {new Date(note.last_modified).toLocaleDateString()}
                    </span>
                    {userData.email === note.user && (
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <ThreePointsSettings className="cursor-pointer" />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="mr-4 mt-2">
                          <DropdownMenuItem
                            className="cursor-pointer"
                            onClick={() => handleOpenNoteCreator(note)}
                          >
                            <span className="flex items-center gap-2 cursor-pointer">
                              <PencilLine className="w-4 h-4" />
                              Edit
                            </span>
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="cursor-pointer"
                            onClick={() => handleDownloadNote(note)}
                          >
                            <span className="flex items-center gap-2 cursor-pointer">
                              <Download className="w-4 h-4" />
                              Download
                            </span>
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="cursor-pointer"
                            onClick={() => handleDeleteNote(note.id)}
                          >
                            <span className="flex items-center gap-2 cursor-pointer text-red-500">
                              <Trash className="w-4 h-4" />
                              Delete
                            </span>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    )}
                  </div>
                </div>
                <div className="flex">
                  <div className="min-w-[500px] pr-20">
                    <div className="space-y-2">
                      <div className="flex">
                        <span className="w-30 font-medium pr-2">Hostname:</span>
                        <span className="flex-1">{note.http_asset?.hostname || 'N/A'}</span>
                      </div>
                      <div className="flex">
                        <span className="w-30 font-medium pr-2">Port:</span>
                        <span className="flex-1">{note.http_asset?.port || 'N/A'}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 border-l pl-20">
                    <div id={`markdown-preview-${note.id}`}>
                      <MarkdownViewer className="max-w-[800px]" markdown={note.text} />
                    </div>
                  </div>
                </div>
              </div>
              {index < data.length - 1 && <hr className="my-4 border-border border-2" />}
            </>
          ))}
        </div>
      </ScrollArea>
      {showNoteCreator && (
        <NoteCreator
          headerContent={editNote ? `Note created on ${formatDate(editNote.created)}` : 'New Note'}
          initialContent={editNote ? editNote.text : ''}
          noteId={editNote ? editNote.id : null}
          assetId={editNote?.http_asset?.id}
          onAfterSave={onAfterSave}
          onCloseAction={handleCloseNoteCreator}
        />
      )}
    </div>
  );
};

export default Notes;
