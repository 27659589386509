import { fetchWrapper } from '../lib/fetchWrapper';
import {
  IPAddressV4,
  IPAddressV4Detail,
  IPAddressV4Response,
} from '../types/api/response/ipaddress';

export const getIP = async (ipId: number): Promise<IPAddressV4> => {
  const { data } = await fetchWrapper.get<IPAddressV4>(`/ipaddress/${encodeURIComponent(ipId)}`);
  return data;
};

export const getIPSuggestions = async (startingWith: string): Promise<IPAddressV4Response> => {
  const { data } = await fetchWrapper.get<IPAddressV4Response>('/ipaddress', {
    params: {
      search: startingWith,
    },
  });
  return data;
};

export const getIPAddressV4Detail = async (id: string) => {
  const response = await fetchWrapper.get<IPAddressV4Detail>(`/ipaddress/${id}`);
  return response.data;
};

export default {
  getIP,
  getIPSuggestions,
};
