import React, { useEffect, useState } from 'react';
import NotesList from './NotesList';
import NoteCreator from './NoteCreator';
import { deleteNote, fetchNotesData } from './NotesAPI';
import { Button } from '@/components/ui/button';
import { StickyNote } from 'lucide-react';

const NotesTabDisplay = ({ assetid, hostname }) => {
  const [showNoteCreator, setShowNoteCreator] = useState(false);
  const [notes, setNotes] = useState([]);
  const [editNote, setEditNote] = useState(null);

  useEffect(() => {
    const getNotes = async () => {
      const data = await fetchNotesData({ rowId: assetid });
      setNotes(data);
    };
    getNotes();
  }, [assetid]);

  const handleOpenNoteCreator = () => {
    setShowNoteCreator(true);
  };

  const startNoteEdit = (noteToEdit) => {
    setEditNote(noteToEdit);
    setShowNoteCreator(true);
  };

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleCloseNoteCreator = () => {
    setShowNoteCreator(false);
    setEditNote(null);
  };

  const onAfterSave = async (technology_id) => {
    const data2 = await fetchNotesData({ rowId: technology_id });
    setNotes(data2);
    setShowNoteCreator(false);

    if (editNote) {
      setEditNote(null);
    }
  };

  const handleDeleteNote = async (noteId, technology_id) => {
    await deleteNote(noteId);
    const data = await fetchNotesData({ rowId: technology_id });
    setNotes(data);
  };

  return (
    <div>
      <NotesList
        notes={notes}
        onDelete={(noteToDelete) => handleDeleteNote(noteToDelete, assetid)}
        onEdit={(noteToEdit) => startNoteEdit(noteToEdit)}
      />
      <Button className="bg-[#f05941] hover:bg-[#f05941]/90" onClick={handleOpenNoteCreator}>
        <StickyNote className="h-4 w-4" />
        Create New Note
      </Button>
      {showNoteCreator && (
        <NoteCreator
          headerContent={
            editNote
              ? `Note created on ${formatDate(editNote.created)}`
              : `New Note for ${hostname || 'Asset'}`
          }
          initialContent={editNote ? editNote.text : ''}
          assetId={assetid}
          noteId={editNote ? editNote.id : null}
          onAfterSave={onAfterSave}
          onCloseAction={handleCloseNoteCreator}
        />
      )}
    </div>
  );
};

export default NotesTabDisplay;
