import { getServices } from '@/api/services.api';
import { ServiceEntry, ServiceResponse } from '@/types/api/response/services';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import { formatTimestamp } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { parseAsArrayOf, parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { Link } from 'react-router-dom';

const Services = () => {
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [selectedPorts, setSelectedPorts] = useQueryState(
    'ports',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [selectedServices, setSelectedServices] = useQueryState(
    'services',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [selectedBanners, setSelectedBanners] = useQueryState(
    'banners',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [selectedASNs, setSelectedASNs] = useQueryState(
    'asn',
    parseAsArrayOf(parseAsString).withDefault([]),
  );

  const { data, isLoading, error } = useQuery<ServiceResponse>({
    queryKey: ['services', page, selectedPorts, selectedServices, selectedBanners, selectedASNs],
    queryFn: async () => {
      const response = await getServices({
        page,
        ports: selectedPorts.join(','),
        services: selectedServices.join(','),
        serviceversion: selectedBanners.join(','),
        asn: selectedASNs.join(','),
      });
      return response.data;
    },
  });

  const ports = data?.port_overview
    ? data.port_overview.map((port) => ({
        label: `${port.port} (${port.count})`,
        value: port.port.toString(),
      }))
    : [];
  const services = data?.service_overview
    ? data.service_overview.map((service) => ({
        label: `${service.service} (${service.count})`,
        value: service.service,
      }))
    : [];
  const banners = data?.service_version_overview
    ? data.service_version_overview.map((banner) => ({
        label: `${
          banner.service_version === '' ? 'Unknown' : banner.service_version
        } (${banner.count})`,
        value: banner.service_version,
      }))
    : [];
  const asns = data?.asn_overview
    ? data.asn_overview.map((asn) => ({
        label: `${asn.asn} (${asn.count})`,
        value: asn.asn.toString(),
      }))
    : [];

  const handleSelectPorts = (value: string | string[]) => {
    setSelectedPorts(value as string[]);
    setPage(1);
  };

  const handleSelectServices = (value: string | string[]) => {
    setSelectedServices(value as string[]);
    setPage(1);
  };

  const handleSelectBanners = (value: string | string[]) => {
    setSelectedBanners(value as string[]);
    setPage(1);
  };

  const handleSelectASNs = (value: string | string[]) => {
    setSelectedASNs(value as string[]);
    setPage(1);
  };

  const columns: ColumnDef<ServiceEntry>[] = [
    {
      header: 'IP Address',
      accessorKey: 'ipaddress',
      cell: ({ row }) => {
        return (
          <Link
            to={`/ipaddress/${row.original.ps_ipaddress}?from=services`}
            target="_self"
            className="hover:underline"
            title="View IP Address"
            data-click-bypass="true"
          >
            {row.original.ps_ipaddress}
          </Link>
        );
      },
    },
    {
      header: 'Port',
      accessorKey: 'ps_port',
      cell: ({ row }) => {
        return (
          <div
            onClick={() => handleSelectPorts([row.original.ps_port.toString()])}
            className="cursor-pointer hover:underline"
            title="Click to select port"
          >
            {row.original.ps_port || 'N/A'}
          </div>
        );
      },
    },
    {
      header: 'Service',
      accessorKey: 'ss_service',
      cell: ({ row }) => {
        return (
          <div
            onClick={() => handleSelectServices([row.original.ss_service])}
            className="cursor-pointer hover:underline"
            title="Click to select service"
          >
            {row.original.ss_service || 'N/A'}
          </div>
        );
      },
    },
    {
      header: 'Service Version',
      accessorKey: 'ss_serviceversion',
      cell: ({ row }) => {
        return (
          <div
            onClick={() => handleSelectBanners([row.original.ss_serviceversion])}
            className="cursor-pointer hover:underline"
            title="Click to select service version"
          >
            {row.original.ss_serviceversion || 'N/A'}
          </div>
        );
      },
    },
    {
      header: 'Last Scanned',
      accessorKey: 'ps_last_scanned',
      cell: ({ row }) => {
        return <div>{formatTimestamp(row.original.ps_last_scanned)}</div>;
      },
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const filters: AvailableFilter[] = [
    {
      label: 'Port',
      state: selectedPorts,
      type: 'multiSelect',
      key: 'ports',
      setState: handleSelectPorts,
      placeholder: 'Select Ports',
      options: ports,
    },
    {
      label: 'Service',
      state: selectedServices,
      type: 'multiSelect',
      key: 'services',
      setState: handleSelectServices,
      placeholder: 'Select Services',
      options: services,
    },
    {
      label: 'Service Version',
      state: selectedBanners,
      type: 'multiSelect',
      key: 'banners',
      setState: handleSelectBanners,
      placeholder: 'Select Service Versions',
      options: banners,
    },
    {
      label: 'ASN',
      state: selectedASNs,
      type: 'multiSelect',
      key: 'asn',
      setState: handleSelectASNs,
      placeholder: 'Select ASNs',
      options: asns,
    },
  ];

  const handleClearAllFilters = () => {
    setSelectedPorts([]);
    setSelectedServices([]);
    setSelectedBanners([]);
    setSelectedASNs([]);
    setPage(1);
  };

  return (
    <>
      <div className="p-4 max-w-[2000px] mx-auto">
        <div className="flex justify-between items-center mb-4 gap-4">
          <UniversalFilter filters={filters} clearAllFilters={handleClearAllFilters} />
        </div>
        <DataTable
          columns={columns}
          data={data?.entries || []}
          loading={isLoading}
          currentPage={page}
          totalPages={data?.total_pages || 1}
          totalEntries={data?.total_count || 0}
          onPageChange={handlePageChange}
          error={error}
          tableHeight="calc(100vh - 220px)"
        />
      </div>
    </>
  );
};

export default Services;
