import { UpdateIssuePayload } from '@/api/issues.api';
import MarkdownEditor from '@/components/Markdown/MarkdownEditor';
import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { useFeatureFlag } from '@/context/FeatureFlagContext';
import { useAttachedImages } from '@/hooks/useAttachedImages';
import { NVDVulnerability } from '@/types/api/response/nvd';
import ExpertChat from '@/v2/components/AIChat/ExpertChat';
import NavSpecularLogo from '@/v2/icons/nav-specular-logo.svg?react';
import { useGetIssue, useUpdateIssueMutation } from '@/v2/queries/issues';
import { getUsersQuery } from '@/v2/queries/users';
import { useQuery } from '@tanstack/react-query';
import { ArrowLeft, Calendar, Clock } from 'lucide-react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AssigneeSelector } from './AssigneeSelector';
import { getSeverityDisplay } from './Issues';
import { ResourceSelector } from './ResourceSelector';
import { StatusSelector } from './StatusSelector';
import { VulnerabilitySelector } from './VulnerabilitySelector';
import { cn } from '@/lib/utils';

const IssueDetails = () => {
  const { id } = useParams();
  const { mutate: updateIssue, isPending: updateIssueLoading } = useUpdateIssueMutation();
  const { data: issue, isPending: loading, error } = useGetIssue(Number(id));
  const { data: users, isPending: loadingUsers } = useQuery(getUsersQuery());
  const navigate = useNavigate();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const { addImage, resetImages, imageMap } = useAttachedImages();
  const ISSUES_EXPERT_CHAT_ENABLED = useFeatureFlag('ISSUES_EXPERT_CHAT');
  const [isChatVisible, setIsChatVisible] = useState(true);

  const handleChatVisibilityChange = (isVisible: boolean) => {
    setIsChatVisible(isVisible);
  };

  const handleImageUpload = async (file: File) => {
    const url = await addImage(file);
    return url;
  };

  const handleUpdate = async (updates: Partial<UpdateIssuePayload>) => {
    if (!issue) return;
    try {
      const updatesWithScreenshots: Partial<UpdateIssuePayload> =
        Object.keys(imageMap).length > 0
          ? { ...updates, _uploaded_screenshots: imageMap }
          : updates;

      updateIssue({
        issueId: issue.issue_number,
        updates: {
          ...issue,
          ...updatesWithScreenshots,
        },
      });

      resetImages();
    } catch (err) {
      console.error('Failed to update issue:', err);
    }
  };

  const handleResourceSelect = (resourceType: string | null, resource: any) => {
    if (resource && resourceType) {
      handleUpdate({
        affected_resource_model_type: resourceType,
        affected_resource_object_id: resource.id,
      });
    }
  };

  const handleClearResource = () => {
    if (issue) {
      handleUpdate({
        affected_resource_model_type: null,
        affected_resource_object_id: null,
      });
    }
  };

  const handleVulnerabilitySelect = (
    vulnerabilityType: string | null,
    vulnerability: NVDVulnerability | null,
  ) => {
    if (vulnerability && vulnerabilityType) {
      handleUpdate({
        vuln_model_type: vulnerabilityType,
        vuln_object_id: vulnerability.id,
      });
    }
  };

  const handleClearVulnerability = () => {
    if (issue) {
      handleUpdate({
        vuln_model_type: null,
        vuln_object_id: null,
      });
    }
  };

  const handleTitleBlur = () => {
    handleUpdate({ title: editedTitle });
    setIsEditingTitle(false);
  };

  const handleDescriptionBlur = () => {
    handleUpdate({ description: editedDescription });
    setIsEditingDescription(false);
  };

  const cancelDescriptionEdit = () => {
    setIsEditingDescription(false);
    setEditedDescription(issue.description);
  };

  const issueDetailsV1 = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-[1fr,400px] gap-6">
        {/* Main Content col */}
        <div className="space-y-6 min-w-0 h-full">
          <Card className="h-full">
            <CardHeader className="space-y-4">
              {isEditingTitle ? (
                <Input
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={handleTitleBlur}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleTitleBlur();
                    }
                  }}
                  className="text-xl font-semibold h-auto py-1"
                  autoFocus
                />
              ) : (
                <h1
                  className="text-xl font-semibold cursor-pointer hover:text-muted-foreground transition-colors"
                  onClick={() => {
                    setEditedTitle(issue.title);
                    setIsEditingTitle(true);
                  }}
                >
                  {issue.title}
                </h1>
              )}
              <h3 className="text-sm font-medium text-muted-foreground">Severity</h3>
              {getSeverityDisplay({
                severity: issue.severity,
                showLabel: true,
                showIcon: true,
              })}
              <div>
                <h3 className="text-sm font-medium mb-2 text-muted-foreground">Resource</h3>
                <ResourceSelector
                  selectedResourceType={issue.affected_resource_model_type}
                  selectedResource={issue.affected_resource}
                  onResourceSelect={handleResourceSelect}
                  onClear={handleClearResource}
                  loading={updateIssueLoading}
                />
              </div>
              <div>
                <h3 className="text-sm font-medium mb-2 text-muted-foreground">Vulnerability</h3>
                <VulnerabilitySelector
                  selectedVulnerabilityType={issue.vuln_model_type}
                  selectedVulnerability={issue.vulnerability}
                  onVulnerabilitySelect={handleVulnerabilitySelect}
                  onClear={handleClearVulnerability}
                  loading={updateIssueLoading}
                />
              </div>
            </CardHeader>
            <CardContent className="space-y-6">
              <div>
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-sm font-medium text-muted-foreground">Description</h3>
                  {isEditingDescription ? (
                    <div className="flex items-center gap-2">
                      <Button size="sm" variant="outline" onClick={cancelDescriptionEdit}>
                        Cancel
                      </Button>

                      <Button size="sm" onClick={handleDescriptionBlur}>
                        Save
                      </Button>
                    </div>
                  ) : (
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => {
                        setEditedDescription(issue.description);
                        setIsEditingDescription(true);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </div>
                {isEditingDescription ? (
                  <MarkdownEditor
                    markdown={editedDescription}
                    onChange={(markdown) => setEditedDescription(markdown)}
                    imageUploadHandler={handleImageUpload}
                    autoFocus
                    embedded
                    className="issue-details-editor"
                  />
                ) : (
                  <div className="rounded-lg border p-4">
                    <MarkdownViewer markdown={issue.description} />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          <Card>
            <CardContent className="pt-6">
              <div className="space-y-4">
                <h3 className="text-sm font-medium mb-2 text-muted-foreground">Issue ID</h3>
                <div>{issue.issue_number}</div>
                <div>
                  <h3 className="text-sm font-medium mb-2 text-muted-foreground">Assignee</h3>
                  <AssigneeSelector
                    users={users?.entries}
                    assigneeId={issue.assigned_to?.user?.id}
                    assigneeName={issue.assigned_to?.user?.username}
                    onAssigneeChange={(userId) => handleUpdate({ assigned_to_id: userId })}
                    loading={loadingUsers}
                  />
                </div>
                <div>
                  <h3 className="text-sm font-medium mb-2 text-muted-foreground">Status</h3>
                  <StatusSelector
                    currentStatus={issue.status}
                    onStatusChange={(status) => handleUpdate({ status })}
                  />
                </div>
                <Separator />
                <div className="space-y-2 text-sm text-muted-foreground">
                  <div className="flex items-center gap-2">
                    <Clock className="h-4 w-4" />
                    <span>Updated {new Date(issue.updated_at).toLocaleString()}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Calendar className="h-4 w-4" />
                    <span>Created {new Date(issue.created_at).toLocaleString()}</span>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  };

  const issueDetailsV2 = () => {
    return (
      <div className="relative">
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel defaultSize={55} minSize={50} maxSize={72}>
            <Card className="h-full w-full max-h-[calc(100vh-12rem)] overflow-y-auto">
              <CardHeader>
                <div className="grid grid-cols-1 md:grid-cols-[1fr,300px] gap-6">
                  <div className="h-full space-y-4">
                    {isEditingTitle ? (
                      <Input
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        onBlur={handleTitleBlur}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleTitleBlur();
                          }
                        }}
                        className="text-xl font-semibold h-auto py-1"
                        autoFocus
                      />
                    ) : (
                      <h1
                        className="text-xl font-semibold cursor-pointer hover:text-muted-foreground transition-colors"
                        onClick={() => {
                          setEditedTitle(issue.title);
                          setIsEditingTitle(true);
                        }}
                      >
                        {issue.title}
                      </h1>
                    )}
                    <h3 className="text-sm font-medium text-muted-foreground">Severity</h3>
                    {getSeverityDisplay({
                      severity: issue.severity,
                      showLabel: true,
                      showIcon: true,
                    })}
                    <div>
                      <h3 className="text-sm font-medium mb-2 text-muted-foreground">Resource</h3>
                      <ResourceSelector
                        selectedResourceType={issue.affected_resource_model_type}
                        selectedResource={issue.affected_resource}
                        onResourceSelect={handleResourceSelect}
                        onClear={handleClearResource}
                        loading={updateIssueLoading}
                      />
                    </div>
                    <div>
                      <h3 className="text-sm font-medium mb-2 text-muted-foreground">
                        Vulnerability
                      </h3>
                      <VulnerabilitySelector
                        selectedVulnerabilityType={issue.vuln_model_type}
                        selectedVulnerability={issue.vulnerability}
                        onVulnerabilitySelect={handleVulnerabilitySelect}
                        onClear={handleClearVulnerability}
                        loading={updateIssueLoading}
                      />
                    </div>
                  </div>
                  <div className="space-y-6">
                    <Card>
                      <CardContent className="pt-6">
                        <div className="space-y-4">
                          <div className="flex items-center gap-2 align-middle justify-items-center justify-between">
                            <h3 className="text-sm font-medium text-muted-foreground">Issue ID</h3>
                            <div className="mr-6">{issue.issue_number}</div>
                          </div>
                          <div className="flex items-center gap-2 align-middle justify-items-center justify-between">
                            <h3 className="text-sm font-medium text-muted-foreground">Assignee</h3>
                            <AssigneeSelector
                              users={users?.entries}
                              assigneeId={issue.assigned_to?.user?.id}
                              assigneeName={issue.assigned_to?.user?.username}
                              onAssigneeChange={(userId) =>
                                handleUpdate({ assigned_to_id: userId })
                              }
                              loading={loadingUsers}
                            />
                          </div>
                          <div className="flex items-center gap-2 align-middle justify-items-center justify-between">
                            <h3 className="text-sm font-medium text-muted-foreground">Status</h3>
                            <StatusSelector
                              currentStatus={issue.status}
                              onStatusChange={(status) => handleUpdate({ status })}
                            />
                          </div>
                          <Separator />
                          <div className="space-y-2 text-sm text-muted-foreground">
                            <div className="flex items-center gap-2">
                              <Clock className="h-4 w-4" />
                              <span>Updated {new Date(issue.updated_at).toLocaleString()}</span>
                            </div>
                            <div className="flex items-center gap-2">
                              <Calendar className="h-4 w-4" />
                              <span>Created {new Date(issue.created_at).toLocaleString()}</span>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="-mt-2">
                  <div
                    className={cn(
                      'flex justify-between items-center py-2 bg-background',
                      isEditingDescription && 'sticky top-0 z-50',
                    )}
                  >
                    <h3 className="text-sm font-medium text-muted-foreground">Description</h3>
                    {isEditingDescription ? (
                      <div className={cn('flex items-center gap-2')}>
                        <Button size="sm" variant="outline" onClick={cancelDescriptionEdit}>
                          Cancel
                        </Button>

                        <Button size="sm" onClick={handleDescriptionBlur}>
                          Save
                        </Button>
                      </div>
                    ) : (
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={() => {
                          setEditedDescription(issue.description);
                          setIsEditingDescription(true);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  {isEditingDescription ? (
                    <MarkdownEditor
                      className="issue-details-editor"
                      markdown={editedDescription}
                      onChange={(markdown) => setEditedDescription(markdown)}
                      imageUploadHandler={handleImageUpload}
                      autoFocus
                      embedded
                    />
                  ) : (
                    <div className="rounded-lg border p-4">
                      <MarkdownViewer markdown={issue.description} />
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          </ResizablePanel>
          {isChatVisible && (
            <>
              <ResizableHandle />
              <ExpertChat
                context={[
                  { type: 'issue', id: issue.issue_number.toString() },
                  { type: 'vulnerability', id: issue.vulnerability?.cve_id },
                ]}
                chatId={`issue:${issue.issue_number}`}
                title={issue.title}
                onClose={() => handleChatVisibilityChange(false)}
                onOpen={() => handleChatVisibilityChange(true)}
              />
            </>
          )}
        </ResizablePanelGroup>
        {!isChatVisible && (
          <Button
            variant="outline"
            size="sm"
            onClick={() => handleChatVisibilityChange(true)}
            className="fixed bottom-4 right-4 flex items-center gap-2"
          >
            <NavSpecularLogo className="h-4 w-4" />
            Open Specular AI
          </Button>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="bg-background">
        <div className="container max-w-7xl mx-auto p-6">
          {/* Header */}
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-4">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => navigate('/issues')}
                className="text-muted-foreground hover:text-foreground"
              >
                <ArrowLeft className="mr-2 h-4 w-4" />
                Issues
              </Button>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-[1fr,300px] gap-6">
            {/* Main Content Skeleton */}
            <div className="space-y-6 min-w-0 h-full">
              <Card className="h-full">
                <CardHeader className="space-y-4">
                  <div className="flex items-center gap-3">
                    <Skeleton className="h-6 w-20" />
                    <Skeleton className="h-4 w-12" />
                  </div>
                  <Skeleton className="h-8 w-3/4" />
                  <div>
                    <h3 className="text-sm font-medium mb-2 text-muted-foreground">Resource</h3>
                    <Skeleton className="h-10 w-full" />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium mb-2 text-muted-foreground">
                      Vulnerability
                    </h3>
                    <Skeleton className="h-10 w-full" />
                  </div>
                </CardHeader>
                <CardContent className="space-y-6">
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-sm font-medium">Description</h3>
                      <Skeleton className="h-8 w-16" />
                    </div>
                    <Skeleton className="h-[200px] w-full" />
                  </div>
                </CardContent>
              </Card>
            </div>

            {/* Sidebar Skeleton */}
            <div className="space-y-6">
              <Card>
                <CardContent className="pt-6">
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-sm font-medium mb-2">Assignee</h3>
                      <Skeleton className="h-10 w-full" />
                    </div>
                    <div>
                      <h3 className="text-sm font-medium mb-2">Status</h3>
                      <Skeleton className="h-10 w-full" />
                    </div>
                    <Separator />
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4" />
                        <Skeleton className="h-4 w-32" />
                      </div>
                      <div className="flex items-center gap-2">
                        <Calendar className="h-4 w-4" />
                        <Skeleton className="h-4 w-32" />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !issue) {
    return (
      <div className="container mx-auto p-4">
        <Card>
          <CardContent className="text-center text-red-500 p-4">
            {error.message || 'Issue not found'}
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className=" bg-background">
      <div className="py-6 px-6 container mx-auto 2xl:px-24">
        {/* Header */}
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-4">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => navigate('/issues')}
              className="text-muted-foreground hover:text-foreground"
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              Issues
            </Button>
          </div>
        </div>

        {ISSUES_EXPERT_CHAT_ENABLED ? issueDetailsV2() : issueDetailsV1()}
      </div>
    </div>
  );
};

export default IssueDetails;
