import { GetVulnerabilitiesParams } from '@/api/investigations.api';
import { VulnerabilityResponse } from '@/types/api/response/investigations';
import CriticalityCard from '@/v2/components/CriticalityCard/CriticalityCard';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import {
  getVulnerabilitiesCountQuery,
  getVulnerabilitiesListQuery,
  getVulnerabilityASNOverviewQuery,
  useGetVulnerabilitiesProductsAndVendorsList,
} from '@/v2/queries/investigation';
import { formatTimestamp } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Filter } from 'lucide-react';
import { parseAsArrayOf, parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { useNavigate } from 'react-router-dom';
export default function Pasive() {
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [asnFilter, setAsnFilter] = useQueryState(
    'asn',
    parseAsArrayOf(parseAsString).withDefault([]),
  );

  const [domainInputValue, setDomainInputValue] = useQueryState(
    'domain_input_value',
    parseAsString.withDefault(''),
  );
  const [domainInputType, setDomainInputType] = useQueryState(
    'domain_input_type',
    parseAsString.withDefault('startswith'),
  );

  const [cveNameFilter, setCveNameFilter] = useQueryState(
    'cve_name_filter',
    parseAsString.withDefault(''),
  );
  const [cveNameFilterOperator, setCveNameFilterOperator] = useQueryState(
    'cve_name_filter_operator',
    parseAsString.withDefault('equals'),
  );

  const [vendorFilter, setVendorFilter] = useQueryState(
    'vendor_filter',
    parseAsString.withDefault(''),
  );
  const [productFilter, setProductFilter] = useQueryState(
    'product_filter',
    parseAsString.withDefault(''),
  );

  const [cvssSeverityFilter, setCvssSeverityFilter] = useQueryState(
    'cvss_severity_filter',
    parseAsString.withDefault(''),
  );

  const [cvssSeverityFilterOperator, setCvssSeverityFilterOperator] = useQueryState(
    'cvss_severity_filter_operator',
    parseAsString.withDefault('greater_than'),
  );

  const [firstObservedFilter, setFirstObservedFilter] = useQueryState(
    'first_observed_filter',
    parseAsString.withDefault(''),
  );

  const [firstObservedFilterOperator, setFirstObservedFilterOperator] = useQueryState(
    'first_observed_filter_operator',
    parseAsString.withDefault('newer_than'),
  );

  const [lastObservedFilter, setLastObservedFilter] = useQueryState(
    'last_observed_filter',
    parseAsString.withDefault(''),
  );

  const [lastObservedFilterOperator, setLastObservedFilterOperator] = useQueryState(
    'last_observed_filter_operator',
    parseAsString.withDefault('newer_than'),
  );

  const getVulnerabilitiesParams: GetVulnerabilitiesParams = {
    page,
    asn: asnFilter.join(','),
    domainname: domainInputValue,
    domainname_filter_operator: domainInputType,
    cve_name: cveNameFilter,
    cve_name_filter_operator: cveNameFilterOperator,
    vendor: vendorFilter,
    product: productFilter,
    product_filter_operator: 'contains',
    vendor_filter_operator: 'contains',
    severity: cvssSeverityFilter,
    severity_filter_operator: cvssSeverityFilterOperator,
    first_observed: firstObservedFilter,
    first_observed_filter_operator: firstObservedFilterOperator,
    last_observed: lastObservedFilter,
    last_observed_filter_operator: lastObservedFilterOperator,
  };

  const navigate = useNavigate();

  const { data, isLoading, error } = useQuery(
    getVulnerabilitiesListQuery(getVulnerabilitiesParams),
  );

  const { data: vulnerabilitiesCount } = useQuery(getVulnerabilitiesCountQuery);

  const { data: asnOverview } = useQuery(getVulnerabilityASNOverviewQuery);

  const { data: vulnerabilitiesProductsAndVendorsList } =
    useGetVulnerabilitiesProductsAndVendorsList();

  const getCVSSScoreClass = (score: string) => {
    const numScore = parseFloat(score);
    if (numScore >= 9.0) return 'bg-[#DB4E4E] text-white p-3 text-center rounded h-full w-full';
    if (numScore >= 7.0) return 'bg-[#D68466] text-white p-3 text-center rounded h-full w-full';
    if (numScore >= 4.0) return 'bg-[#E6E67E] text-black p-3 text-center rounded h-full w-full';
    return 'bg-[#008001] text-white p-3 text-center rounded h-full w-full';
  };

  const handleSelectDomain = (value: string) => {
    setDomainInputValue(value);
    setPage(1);
  };

  const handleSelectDomainType = (value: string) => {
    setDomainInputType(value);
    setPage(1);
  };

  const handleSelectCVE = (value: string) => {
    setCveNameFilter(value);
    setPage(1);
  };

  const handleSelectCVEOperator = (value: string) => {
    setCveNameFilterOperator(value);
    setPage(1);
  };

  const handleSelectVendor = (value: string) => {
    setVendorFilter(value);
    setPage(1);
  };

  const handleSelectProduct = (value: string) => {
    setProductFilter(value);
    setPage(1);
  };

  const handleSelectCVSSSeverity = (value: string) => {
    setCvssSeverityFilter(value);
    setPage(1);
  };

  const handleSelectCVSSSeverityOperator = (value: string) => {
    setCvssSeverityFilterOperator(value);
    setPage(1);
  };

  const handleSelectFirstObserved = (value: string) => {
    setFirstObservedFilter(value);
    setPage(1);
  };

  const handleSelectFirstObservedOperator = (value: string) => {
    setFirstObservedFilterOperator(value);
    setPage(1);
  };

  const handleSelectLastObserved = (value: string) => {
    setLastObservedFilter(value);
    setPage(1);
  };

  const handleSelectLastObservedOperator = (value: string) => {
    setLastObservedFilterOperator(value);
    setPage(1);
  };

  const handleSelectAsn = (value: string[]) => {
    setAsnFilter(value);
    setPage(1);
  };

  const columns: ColumnDef<VulnerabilityResponse>[] = [
    {
      header: 'Domain Name',
      accessorKey: 'http_asset.hostname',
      cell: ({ row }) => (
        <LinkWithAction
          to={`/assets/${row.original.http_asset.id}?from=investigation/passive`}
          dataClickBypass={true}
          title="View Asset details"
          actions={[
            {
              label: 'Set Domain as Filter',
              onClick: () => handleSelectDomain(row.original.http_asset.hostname),
              icon: <Filter />,
            },
          ]}
          className="hover:underline"
        >
          {row.original.http_asset.hostname}
        </LinkWithAction>
      ),
    },
    {
      header: 'Port',
      accessorKey: 'http_asset.port',
    },
    {
      header: () => <div className="text-center">CVE ID</div>,
      accessorKey: 'vulnerability.cve_id',
      cell: ({ row }) => (
        <LinkWithAction
          to={`/vulnerabilities/${row.original.vulnerability.cve_id}?from=investigation/passive`}
          dataClickBypass={true}
          title="View CVE details"
          actions={[
            {
              label: 'Set CVE as Filter',
              onClick: () => handleSelectCVE(row.original.vulnerability.cve_id),
              icon: <Filter />,
            },
          ]}
          className="hover:underline"
        >
          {row.original.vulnerability.cve_id}
        </LinkWithAction>
      ),
    },
    {
      header: 'Vendor',
      accessorFn: (row) => row.vulnerability.cpe_uris.vendor,
      cell: ({ row }) => (
        <div
          className="cursor-pointer hover:underline"
          onClick={() => handleSelectVendor(row.original.vulnerability.cpe_uris.vendor)}
          title="Select Vendor"
          data-click-bypass="true"
        >
          {row.original.vulnerability.cpe_uris.vendor}
        </div>
      ),
    },
    {
      header: 'Product',
      accessorFn: (row) => row.vulnerability.cpe_uris.product,
      cell: ({ row }) => (
        <div
          className="cursor-pointer hover:underline"
          onClick={() => handleSelectProduct(row.original.vulnerability.cpe_uris.product)}
          title="Select Product"
          data-click-bypass="true"
        >
          {row.original.vulnerability.cpe_uris.product}
        </div>
      ),
    },
    {
      accessorKey: 'vulnerability.cvss3_base_score',
      header: () => <div className="text-center">CVSS Base</div>,
      cell: ({ row }) => (
        <div className={`${getCVSSScoreClass(row.original.vulnerability.cvss3_base_score)}`}>
          {row.original.vulnerability.cvss3_base_score}
        </div>
      ),
    },
    {
      accessorKey: 'timestamps',
      header: () => <div className="text-center">Timestamps</div>,
      maxSize: 200,
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <div className="flex gap-4">
              <div className="flex flex-col">
                <span>First Identified:</span>
                <span>Service Last Seen:</span>
              </div>
              <div className="flex flex-col">
                <span className="">{formatTimestamp(row.original.date_added)}</span>
                <span className="">{formatTimestamp(row.original.last_seen)}</span>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleNavigateToAsset = (vulnerability: VulnerabilityResponse) => {
    navigate(`/investigation/passive/${vulnerability.id}`);
  };

  const filters: AvailableFilter[] = [
    {
      type: 'select',
      key: 'vendor',
      label: 'Vendor',
      placeholder: 'Filter by Vendor',
      options: vulnerabilitiesProductsAndVendorsList?.vendors ?? [],
      setState: handleSelectVendor,
      state: vendorFilter,
      onClear: () => {},
    },
    {
      type: 'select',
      key: 'product',
      label: 'Product',
      placeholder: 'Filter by Product',
      options: vulnerabilitiesProductsAndVendorsList?.products ?? [],
      setState: handleSelectProduct,
      state: productFilter,
      onClear: () => {},
    },
    {
      type: 'text-with-operator',
      key: 'cve_name',
      label: 'CVE Name',
      placeholder: 'Filter by CVE Name',
      setState: handleSelectCVE,
      state: cveNameFilter,
      setValueType: handleSelectCVEOperator,
      valueType: cveNameFilterOperator,
      options: [
        {
          label: 'equals',
          value: 'equals',
        },
        {
          label: 'contains',
          value: 'contains',
        },
      ],
      defaultValue: 'equals',
      onClear: () => {
        setCveNameFilter('');
        setCveNameFilterOperator(null);
      },
    },
    {
      type: 'multiSelect',
      key: 'asn',
      label: 'ASN',
      placeholder: 'Filter by ASN',
      options: asnOverview ?? [],
      setState: handleSelectAsn,
      state: asnFilter,
    },
    {
      type: 'text-with-operator',
      key: 'domain',
      label: 'Domain',
      placeholder: 'Filter by Domain',
      setState: handleSelectDomain,
      state: domainInputValue,
      setValueType: handleSelectDomainType,
      valueType: domainInputType,
      onClear: () => {
        setDomainInputValue('');
        setDomainInputType(null);
      },
      options: [
        {
          label: 'starts with',
          value: 'startswith',
        },
        {
          label: 'contains',
          value: 'contains',
        },
      ],
      defaultValue: 'startswith',
    },
    {
      type: 'severitySearch',
      key: 'cvss_severity',
      label: 'CVSS Severity',
      placeholder: 'Example: 7.5',
      setState: handleSelectCVSSSeverity,
      state: cvssSeverityFilter,
      setValueType: handleSelectCVSSSeverityOperator,
      valueType: cvssSeverityFilterOperator,
      onClear: () => {
        setCvssSeverityFilter('');
        setCvssSeverityFilterOperator(null);
      },
      options: [
        {
          label: 'greater than',
          value: 'greater_than',
        },
        {
          label: 'less than',
          value: 'less_than',
        },
      ],
      defaultValue: 'greater_than',
    },
    {
      type: 'text-with-operator',
      key: 'first_observed',
      label: 'First Observed',
      placeholder: 'Example: 1d, or 1h',
      setState: handleSelectFirstObserved,
      state: firstObservedFilter,
      setValueType: handleSelectFirstObservedOperator,
      valueType: firstObservedFilterOperator,
      defaultValue: 'newer_than',
      options: [
        {
          label: 'newer than',
          value: 'newer_than',
        },
        {
          label: 'older than',
          value: 'older_than',
        },
      ],
      onClear: () => {
        setFirstObservedFilter('');
        setFirstObservedFilterOperator(null);
      },
    },
    {
      type: 'text-with-operator',
      key: 'last_observed',
      label: 'Last Observed',
      placeholder: 'Example: 1d, or 1h',
      setState: handleSelectLastObserved,
      state: lastObservedFilter,
      setValueType: handleSelectLastObservedOperator,
      valueType: lastObservedFilterOperator,
      options: [
        {
          label: 'newer than',
          value: 'newer_than',
        },
        {
          label: 'older than',
          value: 'older_than',
        },
      ],
      defaultValue: 'newer_than',
      onClear: () => {
        setLastObservedFilter('');
        setLastObservedFilterOperator(null);
      },
    },
  ];

  const clearAllFilters = () => {
    setPage(1);
    setAsnFilter([]);
    setDomainInputValue('');
    setDomainInputType(null);
    setCveNameFilter('');
    setCveNameFilterOperator(null);
    setVendorFilter('');
    setProductFilter('');
    setCvssSeverityFilter('');
    setCvssSeverityFilterOperator(null);
    setFirstObservedFilter('');
    setFirstObservedFilterOperator(null);
    setLastObservedFilter('');
    setLastObservedFilterOperator(null);
  };

  return (
    <div className="px-4 flex flex-col gap-4 max-w-[2000px] mx-auto">
      <div className="flex flex-row w-full justify-center">
        {CriticalityCard(vulnerabilitiesCount?.overview.critical ?? 0, 'Critical', 'critical')}
        {CriticalityCard(vulnerabilitiesCount?.overview.high ?? 0, 'High', 'high')}
        {CriticalityCard(vulnerabilitiesCount?.overview.medium ?? 0, 'Medium', 'medium')}
        {CriticalityCard(vulnerabilitiesCount?.overview.low ?? 0, 'Low', 'low')}
      </div>
      <div className="w-full flex flex-col gap-4">
        <div className="flex flex-row gap-6 w-full justify-start">
          <UniversalFilter filters={filters} clearAllFilters={clearAllFilters} />
        </div>
        <div className="w-full flex justify-center">
          <DataTable
            columns={columns}
            data={data?.entries ?? []}
            loading={isLoading}
            currentPage={page}
            totalPages={data?.total_pages ?? 1}
            totalEntries={data?.total_count ?? 0}
            onPageChange={handlePageChange}
            error={error}
            tableHeight="calc(100vh - 320px)"
            maxWidth="w-full"
            onRowClick={handleNavigateToAsset}
          />
        </div>
      </div>
    </div>
  );
}
