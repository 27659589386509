import { SuggestionsSelector } from '@/components/common/SuggestionsSelector';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { getNVDSuggestions } from '../../api/nvd.api';
import { NVDVulnerability } from '../../types/api/response/nvd';

interface VulnerabilitySelectorProps {
  placeholderText?: string;
  selectedVulnerabilityType: string | null;
  selectedVulnerability: any | null;
  onVulnerabilitySelect: (vulnerabilityType: string | null, vulnerability: any | null) => void;
  onClear: () => void;
  loading?: boolean;
}

const getVulnerabilityDisplayValue = (vulnerability: NVDVulnerability | null) => {
  if (!vulnerability) return null;
  return (
    <>
      <HoverCard>
        <HoverCardTrigger className="w-fit">
          <span className="">{vulnerability.cve_id || 'Unknown CVE'}</span>
        </HoverCardTrigger>
        <HoverCardContent>
          <p>{vulnerability.description}</p>
        </HoverCardContent>
      </HoverCard>
    </>
  );
};

const getVulnerabilityDisplaySuggestion = (vulnerability: NVDVulnerability) => {
  return vulnerability.cve_id || 'Unknown CVE';
};

const fetchVulnerabilitySuggestions = async (vulnerabilityType: string, query: string) => {
  const response = await getNVDSuggestions(query);
  return response.entries || [];
};

export const VulnerabilitySelector = ({
  placeholderText,
  selectedVulnerabilityType,
  selectedVulnerability,
  onVulnerabilitySelect,
  onClear,
  loading,
}: VulnerabilitySelectorProps) => {
  const vulnerabilityTypes = ['NVDVulnerabilities'];

  return (
    <SuggestionsSelector
      placeholderText={placeholderText || 'Vulnerability'}
      modelTypes={vulnerabilityTypes}
      selectedModelType={selectedVulnerabilityType}
      selectedItem={selectedVulnerability}
      onSelect={onVulnerabilitySelect}
      onClear={onClear}
      fetchSuggestions={fetchVulnerabilitySuggestions}
      getDisplayValue={getVulnerabilityDisplayValue}
      getDisplaySuggestion={getVulnerabilityDisplaySuggestion}
      loading={loading}
    />
  );
};
