import { useCallback, useState, useEffect } from 'react';

interface UseFullscreenProps {
  element: HTMLElement | null;
}

export function useFullscreen({ element }: UseFullscreenProps) {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const updateFullscreenState = () => {
      // Check if the current fullscreen element is our target element
      const fullscreenElement = document.fullscreenElement;
      const isTargetFullscreen =
        fullscreenElement &&
        (fullscreenElement === element ||
          fullscreenElement.contains(element) ||
          element?.contains(fullscreenElement));

      setIsFullscreen(!!isTargetFullscreen);
    };

    // Set initial state
    updateFullscreenState();

    // Listen for changes
    document.addEventListener('fullscreenchange', updateFullscreenState);
    return () => document.removeEventListener('fullscreenchange', updateFullscreenState);
  }, [element]);

  const toggleFullscreen = useCallback(async () => {
    try {
      if (!element) return;

      if (!document.fullscreenElement) {
        await element.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error toggling fullscreen:', error);
    }
  }, [element]);

  return {
    isFullscreen,
    toggleFullscreen,
  };
}
