import React, { createContext, useContext, useState } from 'react';

interface CodeBlockContextType {
  isWrapEnabled: boolean;
  toggleWrap: () => void;
}

const CodeBlockContext = createContext<CodeBlockContextType | undefined>(undefined);

interface CodeBlockProviderProps {
  children: React.ReactNode;
  initialWrapEnabled?: boolean;
}

export function CodeBlockProvider({
  children,
  initialWrapEnabled = false,
}: CodeBlockProviderProps) {
  const [isWrapEnabled, setIsWrapEnabled] = useState(initialWrapEnabled);

  const toggleWrap = () => {
    setIsWrapEnabled((prev) => !prev);
  };

  return (
    <CodeBlockContext.Provider value={{ isWrapEnabled, toggleWrap }}>
      {children}
    </CodeBlockContext.Provider>
  );
}

export function useCodeBlock() {
  const context = useContext(CodeBlockContext);
  if (context === undefined) {
    throw new Error('useCodeBlock must be used within a CodeBlockProvider');
  }
  return context;
}
