import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { ChevronDown, Pencil } from 'lucide-react';
import { useState } from 'react';

interface SuggestionsSelectorProps<T> {
  modelTypes: string[];
  selectedModelType: string | null;
  selectedItem: T | null;
  onSelect: (modelType: string | null, item: T | null) => void;
  onClear: () => void;
  fetchSuggestions: (modelType: string, query: string) => Promise<T[]>;
  getDisplayValue: (item: T, modelType: string) => React.ReactNode;
  getDisplaySuggestion: (item: T, modelType: string) => React.ReactNode;
  placeholderText?: string;
  loading?: boolean;
}

export function SuggestionsSelector<T>({
  modelTypes,
  selectedModelType,
  selectedItem,
  onSelect,
  onClear,
  fetchSuggestions,
  getDisplayValue,
  getDisplaySuggestion,
  placeholderText = 'Select Type',
  loading = false,
}: SuggestionsSelectorProps<T>) {
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [modelType, setModelType] = useState<string | null>(selectedModelType);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.length > 1 && modelType) {
      const data = await fetchSuggestions(modelType, value);
      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion: T) => {
    onSelect(modelType, suggestion);
    setSuggestions([]);
    setInputValue('');
  };

  const handleModelTypeSelect = (modelType: string) => {
    onSelect(modelType, null);
    setModelType(modelType);
    setInputValue('');
    setSuggestions([]);
  };

  const handleClear = () => {
    onClear();
    setModelType(null);
    setInputValue('');
    setSuggestions([]);
  };

  if (selectedModelType === null && loading) {
    return <div>Updating...</div>;
  }

  return (
    <div className="flex flex-col space-y-2">
      {selectedItem ? (
        <div className="flex items-center gap-2 group w-fit">
          <div className="flex-grow p-2 rounded">
            <span className="text-black dark:text-white flex items-center justify-start gap-2">
              {getDisplayValue(selectedItem, selectedModelType!)}
              <button
                onClick={handleClear}
                className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-gray-100 rounded"
              >
                <Pencil className="h-4 w-4 text-gray-500" />
              </button>
            </span>
          </div>
        </div>
      ) : (
        <>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                className="w-[200px] justify-between bg-black text-white hover:bg-black hover:text-white/80"
              >
                {selectedModelType ? selectedModelType : placeholderText}
                <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-gray-50 w-[200px]">
              {modelTypes.map((type) => (
                <DropdownMenuItem
                  key={type}
                  onClick={() => handleModelTypeSelect(type)}
                  className="hover:bg-gray-100"
                >
                  {type}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>

          {modelType && (
            <div className="flex flex-col space-y-2">
              <Input
                value={inputValue}
                onChange={handleInputChange}
                placeholder={`Search ${modelType}`}
                className="bg-transparent border-gray-500 text-black dark:text-white placeholder:text-gray-500 w-full"
              />
              {inputValue && suggestions.length > 0 && (
                <div className="bg-gray-50 shadow-md rounded max-h-40 overflow-y-auto">
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className="p-2 cursor-pointer hover:bg-gray-200 text-black"
                      onClick={() => handleSuggestionSelect(suggestion)}
                    >
                      {getDisplaySuggestion(suggestion, modelType)}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
