import { addAsset } from '@/api/services.api';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/hooks/use-toast';
import { ApiResponse } from '@/lib/fetchWrapper';
import { AddAssetResponse, addAssetFailedResponse } from '@/types/api/response/assets';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { PlusIcon } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  entries: z.string().min(1, { message: 'Entries are required' }),
  notes: z.string().optional(),
});

export const AddAssetDialog = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      entries: '',
      notes: '',
    },
  });
  const [addIPDialogOpen, setAddIPDialogOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>(null);

  const { mutate, isPending } = useMutation<
    ApiResponse<AddAssetResponse, addAssetFailedResponse>,
    Error,
    z.infer<typeof formSchema>
  >({
    mutationFn: async (data) => {
      return await addAsset({
        entries: data.entries.split('\n'),
        notes: data.notes ?? '',
      });
    },
    onSuccess: (response) => {
      if (response.error !== null) {
        // Handle validation error
        const errorEntries = Object.entries(response.error.entries);
        setValidationErrors(response.error.entries);
        toast({
          title: `Failed to add ${errorEntries.length} entries`,
          description: 'Some entries are invalid. Please check the highlighted entries above.',
          variant: 'destructive',
        });
        return;
      }

      setValidationErrors(null);

      if (response.data.failed_to_create_count > 0) {
        toast({
          title: `Failed to add ${response.data.failed_to_create_count} entries`,
          description: `Successfully added ${response.data.created_count} entries, but ${response.data.failed_to_create_count} failed.`,
          variant: 'destructive',
        });
      } else {
        toast({
          title: `Successfully added ${response.data.created_count} entries`,
          description: 'Asset added successfully',
        });
      }

      setAddIPDialogOpen(false);
      form.reset();
    },
    onError: () => {
      setValidationErrors(null);
      toast({
        title: 'Error',
        description: 'Failed to add asset. Please try again.',
        variant: 'destructive',
      });
    },
  });

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    setValidationErrors(null);
    mutate(data);
  };

  return (
    <Dialog open={addIPDialogOpen} onOpenChange={setAddIPDialogOpen}>
      <DialogTrigger>
        <Button className="flex items-center gap-2 bg-v2-orange text-white hover:bg-v2-orange/90">
          <PlusIcon className="w-4 h-4" />
          Add Asset
        </Button>
      </DialogTrigger>
      <DialogContent aria-describedby="dialog-description">
        <DialogHeader>
          <DialogTitle>Add Network Assets</DialogTitle>
          <DialogDescription id="dialog-description">
            Associate network identifiers with your organization
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-4">
              <FormField
                control={form.control}
                name="entries"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Entries</FormLabel>
                    <FormDescription>
                      Enter IP addresses, CIDR blocks, or domain names.
                    </FormDescription>
                    <FormControl>
                      <Textarea
                        {...field}
                        placeholder="Enter IP adresses, CIDR ranges or domains, one per line"
                      />
                    </FormControl>
                    <FormMessage />
                    {validationErrors && (
                      <div className="p-2 border border-red-500 rounded">
                        {Object.entries(validationErrors).map(([entry, message]) => (
                          <div key={entry} className="text-red-500 text-sm">
                            <span className="font-semibold">{entry}:</span> {message}
                          </div>
                        ))}
                      </div>
                    )}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="notes"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Notes</FormLabel>
                    <FormDescription>
                      Optional text to indicate why this Asset is associated with your organization.
                    </FormDescription>
                    <FormControl>
                      <Input {...field} placeholder="Any relevant notes" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Button
              type="submit"
              disabled={isPending}
              className="bg-v2-orange text-white hover:bg-v2-orange/90"
            >
              {isPending ? 'Adding...' : 'Add'}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
