import React, { useEffect } from 'react';
import { ChevronDown, ChevronRight, ExternalLink } from 'lucide-react';

const AssetSources = ({ entry }) => {
  const [expandedDomains, setExpandedDomains] = React.useState(new Set());

  useEffect(() => {
    if (entry && entry.sources) {
      const domains = Object.keys(entry.sources);
      const lastDomain = domains[domains.length - 1];
      setExpandedDomains(new Set([lastDomain]));
    }
  }, [entry]);

  const toggleDomain = (domain) => {
    setExpandedDomains((prev) => {
      const next = new Set(prev);
      if (prev.has(domain)) {
        next.delete(domain);
      } else {
        next.add(domain);
      }
      return next;
    });
  };

  const renderTreeNode = (data, level = 0, parentId = '') => {
    return Object.entries(data).map(([domain, sources], index) => {
      const isObject = typeof sources === 'object' && !Array.isArray(sources);
      const isExpanded = expandedDomains.has(domain);
      const nodeId = `${parentId}${index}`;
      const arrowId = `arrow-${nodeId}`;

      return (
        <div key={domain} className="relative">
          <div className={`flex items-start ${level > 0 ? 'ml-12 mt-2' : 'mt-1'}`}>
            {level > 0 && (
              <div className="absolute -left-8 top-3">
                <svg width="32" height="24" className="overflow-visible">
                  <path
                    d="M0,12 C8,12 16,12 32,12"
                    stroke="#94a3b8"
                    strokeWidth="2"
                    fill="none"
                    markerEnd={`url(#${arrowId})`}
                  />
                  <defs>
                    <marker
                      id={arrowId}
                      markerWidth="10"
                      markerHeight="7"
                      refX="9"
                      refY="3.5"
                      orient="auto"
                    >
                      <polygon points="0 0, 10 3.5, 0 7" fill="#94a3b8" />
                    </marker>
                  </defs>
                </svg>
              </div>
            )}

            <div className="flex-1">
              <button
                onClick={() => toggleDomain(domain)}
                className={`
                  group relative flex w-full items-center justify-between rounded px-3 py-2
                  ${isObject ? 'hover:bg-blue-50 border border-transparent hover:border-blue-200' : 'hover:bg-gray-50'}
                `}
                aria-expanded={isExpanded}
              >
                <div className="flex items-center gap-2">
                  <span className={`font-small ${isObject ? 'text-blue-600' : ''}`}>{domain}</span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-sm text-blue-500 hidden group-hover:inline">
                    {isExpanded ? 'Collapse' : 'Expand'}
                  </span>
                  {isExpanded ? (
                    <ChevronDown className="h-4 w-4 text-blue-500" />
                  ) : (
                    <ChevronRight className="h-4 w-4 text-blue-500" />
                  )}
                </div>
              </button>

              {isExpanded &&
                (isObject ? (
                  <div className="mt-1">{renderTreeNode(sources, level + 1, nodeId)}</div>
                ) : (
                  <ul className="space-y-1 pl-6">
                    {sources.map((source, idx) => {
                      const isCrtSource = source.includes('CRT.SH');
                      const url = isCrtSource ? `https://crt.sh?id=${source.split(':')[1]}` : null;

                      return (
                        <li key={idx}>
                          {isCrtSource ? (
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex items-center text-blue-600 hover:text-blue-800"
                            >
                              <span className="flex-1">{source}</span>
                              <ExternalLink className="ml-2 h-4 w-4 flex-shrink-0" />
                            </a>
                          ) : (
                            <span className="text-gray-700">{source}</span>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                ))}
            </div>
          </div>
        </div>
      );
    });
  };

  if (!entry?.sources) return null;

  return renderTreeNode(entry.sources);
};

export default AssetSources;
