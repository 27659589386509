import { DateTime } from 'luxon';

export function formatTimestamp(timestamp: string, options?: Intl.DateTimeFormatOptions): string {
  const date = DateTime.fromISO(timestamp);
  return date.toLocaleString(options);
}

export function checkIfEmpty(value: string): boolean {
  return value === '' || value === null || value === undefined;
}

export const getTimezones = () => {
  // Major timezones
  const majorTimezones = [
    'UTC',
    // North & Central America
    'America/New_York', // EST/EDT
    'America/Chicago', // CST/CDT
    'America/Denver', // MST/MDT
    'America/Los_Angeles', // PST/PDT
    'America/Toronto', // Eastern Canada
    'America/Mexico_City', // Central Mexico
    // South America
    'America/Sao_Paulo', // Brazil
    'America/Buenos_Aires', // Argentina
    'America/Santiago', // Chile
    'America/Lima', // Peru
    'America/Bogota', // Colombia
    // Europe
    'Europe/London', // GMT/BST
    'Europe/Paris', // Central European
    'Europe/Berlin', // Central European
    // Asia & Oceania
    'Asia/Dubai', // Gulf States
    'Asia/Singapore', // Singapore
    'Asia/Tokyo', // Japan
    'Asia/Shanghai', // China
    'Australia/Sydney', // Eastern Australia
    'Pacific/Auckland', // New Zealand
  ];

  return majorTimezones.map((zone) => ({
    value: zone,
    label:
      zone === 'UTC'
        ? 'UTC'
        : zone
            .replace('_', ' ')
            .split('/')
            .pop()!
            .replace(/([A-Z])/g, ' $1')
            .trim(),
  }));
};

export const getCVSSScoreColor = (score: string) => {
  const numScore = parseFloat(score);
  if (numScore >= 9.0) return '#DB4E4E';
  if (numScore >= 7.0) return '#D68466';
  if (numScore >= 4.0) return '#E6E67E';
  return '#008001';
};
