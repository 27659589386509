import React from 'react';
import { omit } from 'lodash';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { cn } from '@/lib/utils';
import { CodeToolbar } from './CodeToolbar';
import { useCodeBlock, CodeBlockProvider } from '../context/CodeBlockContext';
import { CODE_LINE_WRAP_THRESHOLD } from '../constants';

const trimCode = (code: string | React.ReactNode | null | undefined) =>
  code?.toString().replace(/\n$/, '') ?? '';

interface PreChildProps {
  children?: string;
  className?: string;
}

interface PreProps {
  children?: React.ReactElement<PreChildProps> | React.ReactNode;
  className?: string;
  node?: unknown;
}

export function Pre(props: PreProps) {
  const code = React.isValidElement(props.children) ? trimCode(props.children?.props.children) : '';
  const language = React.isValidElement<PreChildProps>(props.children)
    ? /language-(\w+)/.exec(props.children.props.className || '')?.[1]
    : undefined;

  const lineCount = code.split('\n').length;
  const shouldWrapInitially = lineCount < CODE_LINE_WRAP_THRESHOLD;

  return (
    <CodeBlockProvider initialWrapEnabled={shouldWrapInitially}>
      <div className={cn('markdown-pre rounded-lg overflow-hidden', props.className)}>
        <pre className="my-0">{props.children ?? ''}</pre>
        {code && <CodeToolbar code={code} language={language} />}
      </div>
    </CodeBlockProvider>
  );
}

function CodeBlock({
  code,
  language,
  className,
  ...rest
}: {
  code: string;
  language: string;
  className?: string;
} & React.ComponentProps<typeof SyntaxHighlighter>) {
  const { isWrapEnabled } = useCodeBlock();

  return (
    <SyntaxHighlighter
      {...rest}
      className={cn('markdown-code-block', { 'wrap-long-lines': isWrapEnabled }, className)}
      PreTag="div"
      language={language}
      style={oneDark}
      wrapLongLines={isWrapEnabled}
    >
      {code}
    </SyntaxHighlighter>
  );
}

export function Code(props: { children?: React.ReactNode; className?: string; node?: unknown }) {
  const { children, className, ...rest } = omit(props, 'node');
  const match = /language-(\w+)/.exec(className || '');
  const code = trimCode(children);

  if (!match) {
    return (
      <span className={cn('markdown-inline-code not-prose', className)}>
        <code {...rest}>{code}</code>
      </span>
    );
  }

  return <CodeBlock className={className} code={code} language={match[1]} {...rest} />;
}
