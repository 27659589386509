import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { toast } from '@/hooks/use-toast';
import { useSyncedParams } from '@/hooks/useSynchedState';
import { AssetDetailsWithDetails } from '@/types/api/response/assets';
import { Vulnerability } from '@/types/api/response/investigations';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { useTriggerAIScan } from '@/v2/queries/assets';
import { ColumnDef } from '@tanstack/react-table';
import { FileCode, FileText, Shield, Sparkles, Terminal } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type AssetSearchParamsType = {
  cve?: string;
};

type NucleiVulnerabilityType = AssetDetailsWithDetails['nuclei_vulnerabilities'][0];

export default function AssetDetailVulnerabilities({ asset }: { asset: AssetDetailsWithDetails }) {
  const { synchedState } = useSyncedParams<AssetSearchParamsType>({
    cve: '',
  });

  const [showAiDetails, setShowAiDetails] = useState(false);
  const [selectedCVE, setSelectedCVE] = useState<Vulnerability | null>(null);
  const { mutate: triggerAIScan } = useTriggerAIScan();
  const navigate = useNavigate();

  useEffect(() => {
    if (synchedState.cve) {
      const element = document.getElementById(`vulnerability-${synchedState.cve}`);
      if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    }
  }, [synchedState.cve]);

  const handleAIScan = (vulnerability: Vulnerability) => {
    toast({
      title: `AI Scan started for ${vulnerability.cve_id}`,
      description: 'Please wait while we process your request',
    });
    setSelectedCVE(vulnerability);
    setShowAiDetails(false);
    triggerAIScan(vulnerability.cve_id);
  };

  const handleCloseAiDetails = () => {
    setSelectedCVE(null);
    setShowAiDetails(false);
  };

  const nvdColumns: ColumnDef<Vulnerability>[] = [
    {
      id: 'cve_id',
      header: 'CVE ID',
      cell: ({ row }) => row.original.cve_id,
    },
    {
      id: 'description',
      header: 'Description',
      cell: ({ row }) => row.original.description,
    },
    {
      id: 'cvss3_base_score',
      header: 'CVSSv3 Base Score',
      cell: ({ row }) => row.original.cvss3_base_score,
    },
    {
      id: 'cvss3_exploitability_score',
      header: 'CVSSv3 Exploitability Score',
      cell: ({ row }) => row.original.cvss3_exploitability_score,
    },
    {
      id: 'vendor',
      header: 'Vendor',
      cell: ({ row }) => row.original.cpe_uris.vendor,
    },
    {
      id: 'product',
      header: 'Product',
      cell: ({ row }) => row.original.cpe_uris.product,
    },
  ];

  const nucleiColumns: ColumnDef<NucleiVulnerabilityType>[] = [
    {
      id: 'description',
      header: 'Description',
      cell: ({ row }) => row.original.nuclei_template.description ?? 'N/A',
    },
    {
      id: 'severity',
      header: 'Severity',
      cell: ({ row }) => row.original.nuclei_template.severity,
    },
    {
      id: 'name',
      header: 'Name',
      cell: ({ row }) => row.original.nuclei_template.name,
    },
  ];

  return (
    <>
      <Sheet open={showAiDetails} onOpenChange={handleCloseAiDetails}>
        <SheetContent className="w-full sm:max-w-[80vw] md:max-w-[70vw] lg:max-w-[50vw] overflow-y-auto">
          <SheetHeader className="mb-4">
            <SheetTitle>AI Details for {selectedCVE?.cve_id}</SheetTitle>
            <SheetDescription>AI Details for the vulnerability</SheetDescription>
          </SheetHeader>
          {selectedCVE?.ai_summary ? (
            <Tabs defaultValue="summary" className="w-full">
              <TabsList>
                <TabsTrigger value="summary">Summary</TabsTrigger>
                <TabsTrigger value="exploit">Exploit</TabsTrigger>
                <TabsTrigger value="remediation">Remediation</TabsTrigger>
              </TabsList>
              <TabsContent value="summary" className="w-full">
                {selectedCVE?.ai_summary && (
                  <div className="grid gap-6 pb-6">
                    <Card>
                      <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                          <Shield className="h-5 w-5" />
                          Vulnerability Summary
                        </CardTitle>
                        <CardDescription>
                          AI-generated analysis of the vulnerability
                        </CardDescription>
                      </CardHeader>
                      <CardContent>
                        <p className="text-sm leading-relaxed text-black">
                          {selectedCVE.ai_summary}
                        </p>
                      </CardContent>
                    </Card>
                  </div>
                )}
              </TabsContent>
              <TabsContent value="exploit" className="w-full">
                {selectedCVE?.ai_exploit ? (
                  <div className="grid gap-6 mt-4">
                    {selectedCVE.ai_exploit.steps && (
                      <Card>
                        <CardHeader>
                          <CardTitle className="flex items-center gap-2">
                            <Terminal className="h-5 w-5 text-red-500" />
                            Exploit Overview
                          </CardTitle>
                          <CardDescription>{selectedCVE.ai_exploit.steps.brief}</CardDescription>
                        </CardHeader>
                      </Card>
                    )}

                    {selectedCVE.ai_exploit.code && (
                      <Card className="min-w-0">
                        <CardHeader>
                          <CardTitle className="flex items-center gap-2">
                            <FileCode className="h-5 w-5 text-red-500" />
                            Proof of Concept
                          </CardTitle>
                          <CardDescription>
                            Step-by-step exploitation process with code examples
                          </CardDescription>
                        </CardHeader>
                        <CardContent className="grid gap-6">
                          {selectedCVE.ai_exploit.steps.steps.map((step, idx) => (
                            <div key={idx} className="grid gap-2 min-w-0">
                              <div className="flex gap-3 items-start min-w-0">
                                <Badge
                                  variant="outline"
                                  className="h-6 w-6 shrink-0 p-0 flex items-center justify-center bg-red-50 text-red-700 border-red-200"
                                >
                                  {idx + 1}
                                </Badge>
                                <MarkdownViewer className="min-w-0" markdown={step} />
                              </div>
                            </div>
                          ))}
                        </CardContent>
                      </Card>
                    )}

                    <Card className="min-w-0">
                      <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                          <FileText className="h-5 w-5 text-red-500" />
                          Exploit Code
                        </CardTitle>
                        <CardDescription>Ready-to-use proof of concept code</CardDescription>
                      </CardHeader>
                      <CardContent>
                        <MarkdownViewer
                          markdown={'```\n' + selectedCVE.ai_exploit.code + '\n```'}
                        />
                      </CardContent>
                    </Card>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4 items-center justify-center p-8">
                    <div className="text-gray-500">No Exploit available</div>
                  </div>
                )}
              </TabsContent>
              <TabsContent value="remediation" className="w-full">
                {selectedCVE?.ai_remediation ? (
                  <div className="grid gap-6 mt-4">
                    <Alert variant="default" className="bg-orange-50 border-orange-200">
                      <Terminal className="h-4 w-4 text-orange-500" />
                      <AlertTitle className="text-orange-700">Remediation Overview</AlertTitle>
                      <AlertDescription className="text-orange-900">
                        {selectedCVE.ai_remediation.brief}
                      </AlertDescription>
                    </Alert>

                    <Card className="min-w-0">
                      <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                          <FileCode className="h-5 w-5 text-orange-500" />
                          Remediation Steps
                        </CardTitle>
                        <CardDescription>
                          Follow these steps to address the vulnerability
                        </CardDescription>
                      </CardHeader>
                      <CardContent className="grid gap-6">
                        {selectedCVE.ai_remediation.steps.map((step, idx) => {
                          return (
                            <div key={idx} className="grid gap-2 min-w-0">
                              <div className="flex gap-3 items-start min-w-0">
                                <Badge
                                  variant="outline"
                                  className="h-6 w-6 shrink-0 p-0 flex items-center justify-center bg-orange-50 text-orange-700 border-orange-200"
                                >
                                  {idx + 1}
                                </Badge>
                                <MarkdownViewer className="min-w-0" markdown={step} />
                              </div>
                            </div>
                          );
                        })}
                      </CardContent>
                    </Card>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4 items-center justify-center p-8">
                    <div className="text-gray-500">No Remediation available</div>
                  </div>
                )}
              </TabsContent>
            </Tabs>
          ) : (
            <div className="flex flex-col gap-4 items-center justify-center p-8">
              <div className="text-gray-500">No AI analysis available</div>
              <Button className="gap-2" onClick={() => handleAIScan(selectedCVE!)}>
                <Sparkles className="h-4 w-4" />
                Run AI Analysis
              </Button>
            </div>
          )}
        </SheetContent>
      </Sheet>
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium mb-4">NVD Vulnerabilities</h3>
          <DataTable
            columns={nvdColumns}
            data={asset.vulnerabilities}
            currentPage={1}
            totalPages={1}
            totalEntries={asset.vulnerabilities.length}
            onPageChange={() => {}}
            error={null}
            tableHeight="calc(50vh - 200px)"
            showPagination={false}
            onRowClick={(row) => {
              navigate(`/vulnerabilities/${row.cve_id}`);
            }}
          />
        </div>
        <div>
          <h3 className="text-lg font-medium mb-4">Nuclei Vulnerabilities</h3>
          <DataTable
            columns={nucleiColumns}
            data={asset.nuclei_vulnerabilities}
            currentPage={1}
            totalPages={1}
            totalEntries={asset.nuclei_vulnerabilities.length}
            onPageChange={() => {}}
            error={null}
            tableHeight="calc(50vh - 200px)"
            showPagination={false}
            onRowClick={(row) => {
              navigate(`/investigation/dynamic/${row.id}`);
            }}
          />
        </div>
      </div>
    </>
  );
}
