export const CODE_BLOCK_LANGUAGES = {
  text: 'Plain Text',
  shell: 'Shell',
  python: 'Python',
  javascript: 'JavaScript',
  typescript: 'TypeScript',
  json: 'JSON',
  html: 'HTML',
  css: 'CSS',
} as const;

export type CodeBlockLanguage = keyof typeof CODE_BLOCK_LANGUAGES;

export const CODE_LINE_WRAP_THRESHOLD = 10;
