import { getIPSuggestions } from '@/api/ipaddress.api';
import { SuggestionsSelector } from '@/components/common/SuggestionsSelector';
import { getAssetSuggestions, getDomainSuggestions } from '@/GridComponents/GridAPI';
import { getResourceDisplayValue } from '@/pages/Issues/ResourceSelector';
import { useState } from 'react';

type IssuesAffectedResourcesFilterProps = {
  affectedResource: string;
  setAffectedResource: (value: string) => void;
  affectedResourceOperator: string;
  setAffectedResourceOperator: (value: string) => void;
};

export const IssuesAffectedResourcesFilter = ({
  affectedResource,
  setAffectedResource,
  affectedResourceOperator,
  setAffectedResourceOperator,
}: IssuesAffectedResourcesFilterProps) => {
  const resourceTypes = ['HTTPAsset', 'Domains', 'IPAddressV4'];
  const [selectedResource, setSelectedResource] = useState<any>(affectedResource);
  const [selectedResourceType, setSelectedResourceType] = useState<string | null>(
    affectedResourceOperator,
  );

  const onResourceSelect = (modelType: string | null, item: any) => {
    setSelectedResource(item);
    setSelectedResourceType(modelType);
    if (item) {
      setAffectedResourceOperator(modelType);
      setAffectedResource(item);
    }
  };

  const onClear = () => {
    setAffectedResource(null);
    setAffectedResourceOperator(null);
    setSelectedResource(null);
    setSelectedResourceType(null);
  };

  const fetchResourceSuggestions = async (resourceType: string, query: string) => {
    if (resourceType === 'HTTPAsset') {
      return await getAssetSuggestions(query, 'startswith');
    } else if (resourceType === 'Domains') {
      return await getDomainSuggestions(query, 'startswith');
    } else if (resourceType === 'IPAddressV4') {
      return await getIPSuggestions(query);
    }
    return [];
  };

  const getResourceDisplaySuggestion = (resource: any, resourceType: string) => {
    if (resourceType === 'HTTPAsset') {
      return `${resource.domain?.domainname || 'Unknown Domain'}:${resource.port || '80'}`;
    } else if (resourceType === 'Domains') {
      return resource.domainname || 'No valid entry';
    } else if (resourceType === 'IPAddressV4') {
      return resource.ipaddress || 'No valid IP';
    }
    return 'Unknown Resource';
  };

  return (
    <SuggestionsSelector
      placeholderText="Affected Resource"
      modelTypes={resourceTypes}
      selectedModelType={selectedResourceType}
      selectedItem={selectedResource}
      onSelect={onResourceSelect}
      onClear={onClear}
      fetchSuggestions={fetchResourceSuggestions}
      getDisplayValue={getResourceDisplayValue}
      getDisplaySuggestion={getResourceDisplaySuggestion}
    />
  );
};
