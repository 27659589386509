import Cookies from 'js-cookie';
import { cookieNames } from '../constants';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type RequestOptions = {
  method?: HttpMethod;
  headers?: Headers;
  params?: Record<string, string | number>;
  body?: unknown;
  token?: string;
  credentials?: RequestCredentials;
};

export interface ApiSuccessResponse<T> {
  data: T;
  error: null;
  status: number;
}

export interface ApiErrorResponse<E = unknown> {
  data: null;
  error: E;
  status: number;
}

export type ApiResponse<T, E = unknown> = ApiSuccessResponse<T> | ApiErrorResponse<E>;

export class HttpError extends Error {
  constructor(
    public status: number,
    public data: unknown,
  ) {
    super(`HTTP Error ${status}`);
    this.name = 'HttpError';
  }
}

export class AuthError extends HttpError {
  constructor(data: unknown) {
    super(403, data);
    this.name = 'AuthError';
  }
}

export class ValidationError extends HttpError {
  constructor(data: unknown) {
    super(400, data);
    this.name = 'ValidationError';
  }
}

export class NotFoundError extends HttpError {
  constructor(data: unknown) {
    super(404, data);
    this.name = 'NotFoundError';
  }
}

const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL;

const buildQueryString = (params: Record<string, string | number | boolean> = {}): string => {
  return new URLSearchParams(
    Object.fromEntries(Object.entries(params).map(([key, value]) => [key, String(value)])),
  ).toString();
};

async function getCSRFToken() {
  try {
    const response = await fetch(BASE_URL + '/get-csrf', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const respAsJSON = await response.json();
    return respAsJSON.csrfToken;
  } catch (error) {
    console.warn('Error obtaining CSRF token:', error);
    return null;
  }
}

async function fetchWrapperFunction<T, E = unknown>(
  endpoint: string,
  options: RequestOptions = {},
): Promise<ApiResponse<T, E>> {
  const { method = 'GET', headers = {}, params = {}, body, token, credentials } = options;

  let CSRFToken = Cookies.get(cookieNames.csrfToken);

  if (!CSRFToken) {
    CSRFToken = await getCSRFToken();
  }

  const defaultHeaders: Record<string, string> = {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...(CSRFToken ? { 'X-CSRFToken': CSRFToken } : {}),
  };

  // Only set Content-Type: application/json if body is not FormData
  if (!(body instanceof FormData)) {
    defaultHeaders['Content-Type'] = 'application/json';
  }

  const config: RequestInit = {
    method,
    headers: new Headers({
      ...defaultHeaders,
      ...headers,
    }),
    credentials: credentials ?? 'include',
  };

  if (body !== undefined) {
    if (
      body instanceof FormData ||
      body instanceof URLSearchParams ||
      body instanceof Blob ||
      typeof body === 'string'
    ) {
      config.body = body;
    } else {
      config.body = JSON.stringify(body);
    }
  }

  const queryString = buildQueryString(params);
  const url = `${BASE_URL}${endpoint}${queryString ? `?${queryString}` : ''}`;

  const response = await fetch(url, config);
  const responseData = await response.json();

  if (response.ok) {
    return {
      data: responseData,
      error: null,
      status: response.status,
    };
  } else {
    // Handle specific error cases
    switch (response.status) {
      case 400:
        throw new ValidationError(responseData);
      case 403:
        throw new AuthError(responseData);
      case 404:
        throw new NotFoundError(responseData);
      default:
        throw new HttpError(response.status, responseData);
    }
  }
}

async function fetchStreamFunction(
  endpoint: string,
  options: RequestOptions = {},
): Promise<Response> {
  const { method = 'POST', headers = {}, body, token, credentials } = options;

  let CSRFToken = Cookies.get(cookieNames.csrfToken);

  if (!CSRFToken) {
    CSRFToken = await getCSRFToken();
  }

  const defaultHeaders: Record<string, string> = {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...(CSRFToken ? { 'X-CSRFToken': CSRFToken } : {}),
    'Content-Type': 'application/json',
  };

  const config: RequestInit = {
    method,
    headers: new Headers({
      ...defaultHeaders,
      ...headers,
    }),
    credentials: credentials ?? 'include',
    body: body ? JSON.stringify(body) : undefined,
  };

  const url = `${BASE_URL}${endpoint}`;
  const response = await fetch(url, config);

  if (!response.ok) {
    throw new HttpError(response.status, await response.json());
  }

  return response;
}

export const fetchWrapper = {
  get: <T, E = unknown>(endpoint: string, options?: Omit<RequestOptions, 'body'>) =>
    fetchWrapperFunction<T, E>(endpoint, { ...options, method: 'GET' }),
  post: <T, E = unknown>(endpoint: string, body: unknown, options?: Omit<RequestOptions, 'body'>) =>
    fetchWrapperFunction<T, E>(endpoint, { ...options, method: 'POST', body }),
  put: <T, E = unknown>(endpoint: string, body: unknown, options?: Omit<RequestOptions, 'body'>) =>
    fetchWrapperFunction<T, E>(endpoint, { ...options, method: 'PUT', body }),
  delete: <T, E = unknown>(endpoint: string, options?: Omit<RequestOptions, 'body'>) =>
    fetchWrapperFunction<T, E>(endpoint, { ...options, method: 'DELETE' }),
  patch: <T, E = unknown>(
    endpoint: string,
    body: unknown,
    options?: Omit<RequestOptions, 'body'>,
  ) => fetchWrapperFunction<T, E>(endpoint, { ...options, method: 'PATCH', body }),
  stream: (endpoint: string, body: unknown, options?: Omit<RequestOptions, 'body'>) =>
    fetchStreamFunction(endpoint, { ...options, method: 'POST', body }),
};

export default fetchWrapper;
