import { fetchWrapper } from '../lib/fetchWrapper';
import { UserSettings, UsersResponse } from '../types/api/response/user';

export const getUsers = async (page: number = 1): Promise<UsersResponse> => {
  const { data } = await fetchWrapper.get<UsersResponse>('/users', {
    params: {
      page,
    },
  });
  return data;
};

export const updateUserSettings = async (settings: UserSettings): Promise<UserSettings> => {
  const { data } = await fetchWrapper.patch<UserSettings>('/user/settings/', settings);
  return data;
};
