import { getUsers, updateUserSettings } from '@/api/users.api';
import { UserSettings } from '@/types/api/response/user';
import { queryOptions, useMutation } from '@tanstack/react-query';

export const getUsersQuery = (page: number = 1) =>
  queryOptions({
    queryKey: ['users', page],
    queryFn: () => getUsers(page),
  });

export const useUpdateUserSettings = () => {
  return useMutation({
    mutationFn: (settings: UserSettings) => updateUserSettings(settings),
  });
};
