import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { DatePicker } from '../DatePicker/DatePicker';
interface DateWithOperatorProps {
  fromDate: string | null;
  operator: string | null;
  operatorOptions: { label: string; value: string }[];
  setFromDate: (value: Date) => void;
  setOperator: (value: string) => void;
}

export default function DateWithOperator({
  fromDate,
  setFromDate,
  operator,
  operatorOptions,
  setOperator,
}: DateWithOperatorProps) {
  return (
    <div className="flex items-start w-fit">
      <Select value={operator} onValueChange={(value) => setOperator(value)}>
        <SelectTrigger>
          <SelectValue placeholder="Select Operator" />
        </SelectTrigger>
        <SelectContent>
          {operatorOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <DatePicker date={fromDate ? new Date(fromDate) : new Date()} setDate={setFromDate} />
    </div>
  );
}
