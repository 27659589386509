import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { DateRange } from 'react-day-picker';

interface DateTimeRangePickerProps {
  dateRange: DateRange | undefined;
  setDateRange: (date: DateRange | undefined) => void;
  quickSelect: string;
  setQuickSelect: (value: string) => void;
  ref?: React.RefObject<HTMLDivElement>;
}

const DateTimeRangePicker = ({
  dateRange,
  setDateRange,
  quickSelect,
  setQuickSelect,
  ref,
}: DateTimeRangePickerProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleQuickSelectChange = (value: string) => {
    setQuickSelect(value);
    const now = new Date();

    switch (value) {
      case '7days':
        setDateRange({
          from: new Date(now.setDate(now.getDate() - 7)),
          to: new Date(),
        });
        break;
      case '14days':
        setDateRange({
          from: new Date(now.setDate(now.getDate() - 14)),
          to: new Date(),
        });
        break;
      case '30days':
        setDateRange({
          from: new Date(now.setDate(now.getDate() - 30)),
          to: new Date(),
        });
        break;
      case '60days':
        setDateRange({
          from: new Date(now.setDate(now.getDate() - 60)),
          to: new Date(),
        });
        break;
      case '90days':
        setDateRange({
          from: new Date(now.setDate(now.getDate() - 90)),
          to: new Date(),
        });
        break;
      case 'alltime':
        setDateRange(undefined);
        break;
      case 'custom':
        break;
    }
  };

  return (
    <div className="flex items-center gap-3 mb-4" ref={ref}>
      <Select value={quickSelect} onValueChange={handleQuickSelectChange}>
        <SelectTrigger
          className={cn(
            'w-[180px] bg-gray-50 border-0 text-black',
            'focus:ring-1 focus:ring-gray-200',
          )}
        >
          <SelectValue placeholder="Time range" />
        </SelectTrigger>
        <SelectContent className="bg-gray-50 border-gray-200">
          <SelectItem value={null} className="text-black focus:bg-gray-100">
            All time
          </SelectItem>
          <SelectItem value="7days" className="text-black focus:bg-gray-100">
            Last 7 days
          </SelectItem>
          <SelectItem value="14days" className="text-black focus:bg-gray-100">
            Last 14 days
          </SelectItem>
          <SelectItem value="30days" className="text-black focus:bg-gray-100">
            Last 30 days
          </SelectItem>
          <SelectItem value="60days" className="text-black focus:bg-gray-100">
            Last 60 days
          </SelectItem>
          <SelectItem value="90days" className="text-black focus:bg-gray-100">
            Last 90 days
          </SelectItem>
          <SelectItem value="custom" className="text-black focus:bg-gray-100">
            Custom range
          </SelectItem>
        </SelectContent>
      </Select>

      <div className="grid gap-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              ref={buttonRef}
              id="date"
              variant={'outline'}
              className={cn(
                'w-[240px] justify-start text-left font-normal bg-gray-50 border-0 text-black hover:bg-gray-100 hover:text-black',
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4 opacity-50" />
              {dateRange?.from ? (
                dateRange.to ? (
                  <>
                    {format(dateRange.from, 'LLL dd, y')} - {format(dateRange.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(dateRange.from, 'LLL dd, y')
                )
              ) : (
                <span>Select date range</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0 bg-gray-50" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={dateRange?.from}
              selected={dateRange}
              onSelect={setDateRange}
              numberOfMonths={2}
              className="rounded-md border bg-gray-50"
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default DateTimeRangePicker;
