import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { cn } from '@/lib/utils';
import { Pre, Code } from './elements/Code';

const disallowedElements = [
  'applet',
  'audio',
  'base',
  'button',
  'canvas',
  'dialog',
  'embed',
  'form',
  'frame',
  'frameset',
  'iframe',
  'keygen',
  'link',
  'marquee',
  'math',
  'meta',
  'noembed',
  'noframes',
  'object',
  'option',
  'param',
  'picture',
  'plaintext',
  'portal',
  'script',
  'select',
  'source',
  'style',
  'svg',
  'template',
  'textarea',
  'title',
  'track',
  'video',
  'xmp',
];

const safeElements: Record<string, string[]> = {
  '*': ['className'],
  a: ['href', 'title', 'target'],
  blockquote: [],
  br: [],
  code: [],
  del: [],
  div: ['align'],
  em: [],
  h1: ['align'],
  h2: ['align'],
  h3: ['align'],
  h4: ['align'],
  h5: ['align'],
  h6: ['align'],
  hr: [],
  img: ['src', 'alt', 'title', 'width', 'height'],
  input: ['type', 'checked', 'disabled'],
  li: [],
  ol: ['start', 'type'],
  p: ['align'],
  pre: [],
  span: [],
  strong: [],
  table: ['summary'],
  td: ['align', 'colspan', 'rowspan'],
  th: ['align', 'colspan', 'rowspan'],
  tr: [],
  ul: ['type'],
};

type SafeElement = keyof typeof safeElements;

interface MarkdownElement {
  tagName?: string;
  properties?: Record<string, unknown>;
}

const allowElement = (element: MarkdownElement) => {
  if (!element.tagName || typeof element.tagName !== 'string') {
    return false;
  }

  const tagName = element.tagName.toLowerCase() as SafeElement;
  const allowedAttrs = [
    ...safeElements['*'],
    ...(tagName in safeElements ? safeElements[tagName] : []),
  ];
  const elementAttrs = Object.keys(element.properties || {});

  if (
    elementAttrs.some(
      (attr) => attr.startsWith('data-') || attr.startsWith('on') || attr === 'style',
    )
  ) {
    // NOTE: We need to know if this is happening
    // eslint-disable-next-line no-console
    console.debug('Blocked data-* or event handler attributes:', element);
    return false;
  }

  if (element.properties?.href && typeof element.properties.href === 'string') {
    const href = element.properties.href.toLowerCase().trim();
    if (
      href.startsWith('javascript:') ||
      href.startsWith('data:') ||
      href.startsWith('vbscript:') ||
      href.includes('\x00') ||
      href.includes('\\')
    ) {
      // NOTE: We need to know if this is happening
      // eslint-disable-next-line no-console
      console.debug('Blocked dangerous href:', element);
      return false;
    }
  }

  if (!elementAttrs.every((attr) => allowedAttrs.includes(attr))) {
    // NOTE: We need to know if this is happening
    // eslint-disable-next-line no-console
    console.debug('Disallowed attributes in MarkdownViewer:', element);
    return false;
  }

  return true;
};

function InputComponent({ type, checked, ...props }: React.InputHTMLAttributes<HTMLInputElement>) {
  if (type === 'checkbox') {
    return <input type="checkbox" defaultChecked={checked} {...props} />;
  }

  return <input type={type} {...props} />;
}

export default function MarkdownViewer({
  markdown,
  className,
  onClick,
}: {
  markdown: string;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <div className={cn('markdown-wrapper', className)} onClick={onClick}>
      <Markdown
        className="markdown-body"
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[[rehypeRaw, { tagfilter: true }]]}
        components={{
          pre: Pre,
          code: Code,
          input: InputComponent,
        }}
        disallowedElements={disallowedElements}
        allowElement={allowElement}
        key={markdown}
      >
        {markdown}
      </Markdown>
    </div>
  );
}
