import {
  createMessage,
  fetchChatHistory,
  mockCreateMessage,
  resetConversation,
  streamMessage,
} from '@/api/messages.api';
import { MessageResponse } from '@/types/api/response/message';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useSendMessage = () => {
  return useMutation({
    mutationFn: (message: MessageResponse) => createMessage(message),
  });
};

export const useMockSendMessage = () => {
  return useMutation({
    mutationFn: (message: MessageResponse) => mockCreateMessage(message),
  });
};

export const useFetchChatHistory = (chatId: string) => {
  return useQuery({
    queryKey: ['chatHistory', chatId],
    queryFn: () => fetchChatHistory(chatId),
  });
};

export const useResetConversation = (chatId: string) => {
  return useMutation({
    mutationFn: () => resetConversation(chatId),
  });
};

export const useStreamMessage = () => {
  return useMutation({
    mutationFn: (message: MessageResponse) => streamMessage(message),
  });
};
