import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { Check, ChevronsUpDown } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

export default function GenericSelectWithOperator({
  options,
  valueType,
  setValueType,
  onClear,
  operator,
  setOperator,
  operatorOptions,
  label,
  placeholder,
}: {
  options: { label: string; value: string }[];
  valueType: string;
  setValueType: (value: string) => void;
  onClear: () => void;
  operator: string;
  setOperator: (value: string) => void;
  operatorOptions: { label: string; value: string }[];
  label: string;
  placeholder: string;
}) {
  const [open, setOpen] = useState(false);
  const selectRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (selectRef.current) {
        selectRef.current.focus();
      }
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleSelect = (currentValue: string) => {
    setValueType(currentValue === valueType ? '' : currentValue);
    setOpen(false);
    // Simulate an escape key press to close the parent dialog
    window.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
  };

  return (
    <div className="flex items-center gap-2">
      <Button variant="outline" onClick={onClear} className="font-normal">
        {label}
      </Button>
      <Select value={operator} onValueChange={setOperator}>
        <SelectTrigger>
          <SelectValue placeholder="Select an option" />
        </SelectTrigger>
        <SelectContent>
          {operatorOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[200px] justify-between font-normal"
            ref={selectRef}
          >
            {valueType
              ? options.find((option) => option.value === valueType)?.label.length > 20
                ? `${options.find((option) => option.value === valueType)?.label.slice(0, 20)}...`
                : options.find((option) => option.value === valueType)?.label
              : 'Select an option...'}
            <ChevronsUpDown className="opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput placeholder={placeholder} className="h-9" />
            <CommandList>
              <CommandEmpty>No option found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem
                    className="font-normal"
                    key={option.value}
                    value={option.value}
                    onSelect={handleSelect}
                  >
                    {option.label.length > 20 ? `${option.label.slice(0, 20)}...` : option.label}
                    <Check
                      className={cn(
                        'ml-auto',
                        valueType === option.value ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
