import { useCallback } from 'react';
import {
  $isCodeBlockNode,
  activeEditor$,
  ButtonWithTooltip,
  CodeBlockNode,
  editorInFocus$,
  iconComponentFor$,
  useCellValues,
} from '@mdxeditor/editor';

export function RemoveCodeBlockButton() {
  const [editorInFocus, editor, iconComponentFor] = useCellValues(
    editorInFocus$,
    activeEditor$,
    iconComponentFor$,
  );

  const handleRemove = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const codeBlockNode = editorInFocus!.rootNode as CodeBlockNode;

      editor?.update(() => {
        if ($isCodeBlockNode(codeBlockNode)) {
          codeBlockNode.remove();
          editor.focus();
        }
      });
    },
    [editor, editorInFocus],
  );

  return (
    <ButtonWithTooltip onClick={handleRemove} title="Remove code block">
      {iconComponentFor('delete_small')}
    </ButtonWithTooltip>
  );
}
