import fetchWrapper, { AuthError } from '@/lib/fetchWrapper';
import { OrganizationResponse, User } from '@/types/api/response/user';

export const getUserDataApi = async (): Promise<User> => {
  try {
    // Make an API request to the server to check if the user is authenticated
    const response = await fetchWrapper.get<{ user: User }>('/user');
    return response.data.user;
  } catch (error) {
    if (error instanceof AuthError) {
      console.error('User is not authenticated:', error);
      throw error;
    }
    console.error('Error getting user data:', error);
    throw error;
  }
};

export const getOrganizationsApi = async (): Promise<OrganizationResponse> => {
  try {
    const response = await fetchWrapper.get<OrganizationResponse>('/organizations');
    return response.data;
  } catch (error) {
    console.error('Error getting organizations:', error);
    throw error;
  }
};

export const loginApi = async (email: string, password: string): Promise<Response> => {
  try {
    const response = await fetchWrapper.post<Response>('/login', { email, password });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const forgotPasswordApi = async (email: string): Promise<Response> => {
  try {
    const response = await fetchWrapper.post<Response>('/password-reset-request', { email });
    return response.data;
  } catch (error) {
    console.error('Error sending forgot password email:', error);
    throw error;
  }
};

export const registerApi = async (formData: {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  registration_key: string;
}): Promise<Response> => {
  try {
    const response = await fetchWrapper.post<Response>('/register', formData);
    return response.data;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};

export const resetPasswordApi = async (token: string): Promise<Response> => {
  try {
    const response = await fetchWrapper.get<Response>(`/change-password?token=${token}`);
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};

export const changePasswordApi = async (
  new_password: string,
  confirm_password: string,
  token: string,
): Promise<Response> => {
  try {
    const response = await fetchWrapper.post<Response>('/change-password', {
      new_password,
      confirm_password,
      token,
    });
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};
