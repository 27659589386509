import { useIPAddressV4Detail } from '@/v2/queries/ipAddress';
import { ArrowLeft } from 'lucide-react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { IPAddressDashboard } from './IPDashboard';
import { IPAddressDashboardSkeleton } from './IPDashboardSkeleton';
export const IPAdressDetailsView = () => {
  const { id } = useParams();
  const { data, isLoading, error } = useIPAddressV4Detail(id);
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');
  const assetId = searchParams.get('assetId');
  const fromText = () => {
    switch (from) {
      case 'assets':
        return 'Back to Assets';
      case 'issues':
        return 'Back to Issues';
      default:
        return 'Back Home ';
    }
  };

  const backTo = () => {
    switch (from) {
      case 'assets':
        if (assetId) {
          return `/assets/${assetId}`;
        }
        return '/assets';
      case 'issues':
        return '/issues';
      default:
        return '/';
    }
  };

  if (isLoading) return <IPAddressDashboardSkeleton />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div className="p-4">
        <Link
          to={backTo()}
          className="inline-flex items-center gap-2 text-sm text-muted-foreground hover:text-foreground"
        >
          <ArrowLeft className="h-4 w-4" />
          {fromText()}
        </Link>
      </div>
      <IPAddressDashboard data={data} />
    </div>
  );
};
