import { SpecularLink } from '@/components/common/SpecularLink';
import { SuggestionsSelector } from '@/components/common/SuggestionsSelector';
import { getAssetSuggestions, getDomainSuggestions } from '../../GridComponents/GridAPI';
import { getIPSuggestions } from '../../api/ipaddress.api';

interface ResourceSelectorProps {
  placeholderText?: string;
  selectedResourceType: string | null;
  selectedResource: any;
  onResourceSelect: (resourceType: string | null, resource: any) => void;
  onClear: () => void;
  loading?: boolean;
}

export const getResourceDisplayValue = (resource: any, resourceType: string) => {
  if (!resource) return null;
  if (resourceType === 'Domains') {
    return resource.domainname || 'Unknown Domain';
  } else if (resourceType === 'HTTPAsset') {
    const hostname = resource.domain?.domainname || resource.hostname || 'Unknown Host';
    const port = resource.port || '80';
    return (
      <SpecularLink
        className="flex items-center"
        title="View Asset Details"
        resourceType="HTTPAsset"
        id={resource.id}
      >
        <span className="flex items-center">{`${hostname}:${port}`}</span>
      </SpecularLink>
    );
  } else if (resourceType === 'IPAddressV4') {
    return (
      <SpecularLink
        className="hover:underline flex items-center"
        title="View IP Address Details"
        resourceType="IPAddressV4"
        id={resource.id}
      >
        <span className="flex items-center">{resource.ipaddress || 'Unknown IP'}</span>
      </SpecularLink>
    );
  }
  return 'Unknown Resource';
};

const getResourceDisplaySuggestion = (resource: any, resourceType: string) => {
  if (resourceType === 'HTTPAsset') {
    return `${resource.domain?.domainname || 'Unknown Domain'}:${resource.port || '80'}`;
  } else if (resourceType === 'Domains') {
    return resource.domainname || 'No valid entry';
  } else if (resourceType === 'IPAddressV4') {
    return resource.ipaddress || 'No valid IP';
  }
  return 'Unknown Resource';
};

const fetchResourceSuggestions = async (resourceType: string, query: string) => {
  if (resourceType === 'HTTPAsset') {
    return await getAssetSuggestions(query, 'startswith');
  } else if (resourceType === 'Domains') {
    return await getDomainSuggestions(query, 'startswith');
  } else if (resourceType === 'IPAddressV4') {
    return await getIPSuggestions(query);
  }
  return [];
};

export const ResourceSelector = ({
  selectedResourceType,
  selectedResource,
  onResourceSelect,
  onClear,
  loading,
  placeholderText,
}: ResourceSelectorProps) => {
  const resourceTypes = ['HTTPAsset', 'Domains', 'IPAddressV4'];

  return (
    <SuggestionsSelector
      placeholderText={placeholderText || 'Affected Resource'}
      modelTypes={resourceTypes}
      selectedModelType={selectedResourceType}
      selectedItem={selectedResource}
      onSelect={onResourceSelect}
      onClear={onClear}
      fetchSuggestions={fetchResourceSuggestions}
      getDisplayValue={getResourceDisplayValue}
      getDisplaySuggestion={getResourceDisplaySuggestion}
      loading={loading}
    />
  );
};
