import {
  fetchASNOverview,
  fetchAssetDetailsApi,
  fetchAssetHistoryApi,
  fetchAssetsApi,
  FetchAssetsParams,
  fetchAssetURLsApi,
  fetchAssetURLsOverviewApi,
  getAssetSuggestionsApi,
  triggerAIScanApi,
} from '@/api/assets.api';
import { mapAssetData, mapAssetDetailsData } from '@/hooks/useAssetQueries';
import { AssetHistoryResponse, AssetURLResponse, Suggestion } from '@/types/api/response/assets';
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';

type GetAssetSuggestionsParams = {
  assetFilterValue: string;
  assetFilter: string;
  onlyVulnerabilities: boolean;
  enabled: boolean;
  select?: (data: Suggestion[]) => Suggestion[];
};

interface AssetsDetailParams {
  assetId: number;
}

interface AssetsURLParams {
  assetId: number;
  page: number;
  technology: string;
  extension: string;
  statusCode: string;
}

interface AssetsHistoryParams {
  assetId: number;
}

const assetsKeysFactory = {
  all: ['assets'] as const,
  suggestions: ({
    assetFilterValue,
    assetFilter,
  }: {
    assetFilterValue: string;
    assetFilter: string;
  }) => [...assetsKeysFactory.all, 'suggestions', assetFilterValue, assetFilter] as const,
  list: (params: FetchAssetsParams) => [...assetsKeysFactory.all, 'list', params] as const,
  details: ({ assetId }: AssetsDetailParams) =>
    [...assetsKeysFactory.all, 'details', assetId] as const,
  asnOverview: () => [...assetsKeysFactory.all, 'asnOverview'] as const,
  urlsOverview: ({ assetId }: AssetsDetailParams) =>
    [...assetsKeysFactory.all, 'urlsOverview', assetId] as const,
  urls: ({ assetId, page, technology, extension, statusCode }: AssetsURLParams) =>
    [...assetsKeysFactory.all, 'urls', assetId, page, technology, extension, statusCode] as const,
  history: ({ assetId }: AssetsHistoryParams) =>
    [...assetsKeysFactory.all, 'history', assetId] as const,
  aiScan: ({ cveId }: { cveId: string }) => [...assetsKeysFactory.all, 'aiScan', cveId] as const,
};

const getAssetsListFn = async (params: FetchAssetsParams) => {
  const response = await fetchAssetsApi(params);
  return {
    assets: response.entries.map(mapAssetData),
    totalPages: response.total_pages,
    totalCount: response.total_count,
  };
};

export const getAssetsListQuery = (params: FetchAssetsParams = {}) => ({
  queryKey: assetsKeysFactory.list(params),
  queryFn: () => getAssetsListFn(params),
});

export const useGetAssetsList = (params: FetchAssetsParams) =>
  useQuery({
    queryKey: assetsKeysFactory.list(params),
    queryFn: () => getAssetsListFn(params),
  });

export const usePrefetchAssetsList = () => {
  const queryClient = useQueryClient();
  return async (params: FetchAssetsParams) => {
    await queryClient.prefetchQuery({
      queryKey: assetsKeysFactory.list(params),
      queryFn: () => getAssetsListFn(params),
      staleTime: 1000 * 60 * 5, // 5 minutes
    });
  };
};

export const getAssetSuggestionsQuery = ({
  assetFilterValue,
  assetFilter,
  onlyVulnerabilities,
  enabled,
  select,
}: GetAssetSuggestionsParams) => ({
  queryKey: ['assetSuggestions', assetFilterValue, assetFilter],
  queryFn: () => getAssetSuggestionsApi(assetFilterValue, assetFilter, onlyVulnerabilities),
  enabled,
  select,
});

export const getASNOverviewQuery = () => ({
  queryKey: ['asnOverview'],
  queryFn: () => fetchASNOverview(),
});

export const getAssetURLsOverviewQuery = (id: number) =>
  queryOptions({
    queryKey: assetsKeysFactory.urlsOverview({ assetId: id }),
    queryFn: () => fetchAssetURLsOverviewApi(id),
  });

export const useGetAssetDetails = (id: number) =>
  useQuery({
    queryKey: assetsKeysFactory.details({ assetId: id }),
    queryFn: async () => {
      const response = await fetchAssetDetailsApi(id.toString());
      return mapAssetDetailsData(response);
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

export const usePrefetchAssetDetails = () => {
  const queryClient = useQueryClient();
  return async ({ assetId }: AssetsDetailParams) => {
    await queryClient.prefetchQuery({
      queryKey: assetsKeysFactory.details({ assetId }),
      queryFn: async () => {
        const response = await fetchAssetDetailsApi(assetId.toString());
        return mapAssetDetailsData(response);
      },
    });
  };
};

export const useGetAssetURLsQuery = ({
  assetId,
  page,
  technology,
  extension,
  statusCode,
}: AssetsURLParams): UseQueryResult<AssetURLResponse, Error> =>
  useQuery({
    queryKey: assetsKeysFactory.urls({ assetId, page, technology, extension, statusCode }),
    queryFn: () => fetchAssetURLsApi(assetId, page, technology, extension, statusCode),
  });

export const usePrefetchAssetURLsQuery = ({
  assetId,
  page,
  technology,
  extension,
  statusCode,
}: AssetsURLParams): void => {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery({
    queryKey: assetsKeysFactory.urls({ assetId, page, technology, extension, statusCode }),
    queryFn: () => fetchAssetURLsApi(assetId, page, technology, extension, statusCode),
  });
};

export const useGetAssetHistoryQuery = ({
  assetId,
}: AssetsHistoryParams): UseQueryResult<AssetHistoryResponse, Error> =>
  useQuery({
    queryKey: assetsKeysFactory.history({ assetId }),
    queryFn: () => fetchAssetHistoryApi(assetId),
    enabled: !!assetId,
  });

export const usePrefetchAssetHistoryQuery = () => {
  const queryClient = useQueryClient();

  return async ({ assetId }: AssetsHistoryParams) => {
    await queryClient.prefetchQuery({
      queryKey: assetsKeysFactory.history({ assetId }),
      queryFn: () => fetchAssetHistoryApi(assetId),
    });
  };
};

export const useTriggerAIScan = () => {
  return useMutation({
    mutationFn: triggerAIScanApi,
  });
};
