import { useCallback } from 'react';
import { activeEditor$, ButtonWithTooltip, useCellValues } from '@mdxeditor/editor';
import { FullscreenIcon, MinimizeIcon } from 'lucide-react';
import { useFullscreen } from '@/hooks/useFullscreen';

export function FullScreenToggle() {
  const [activeEditor] = useCellValues(activeEditor$);
  const editorEl = activeEditor?.getRootElement();
  const editorElWrapper = editorEl?.closest('.markdown-editor') as HTMLElement;

  const { isFullscreen, toggleFullscreen } = useFullscreen({
    element: editorElWrapper || editorEl || null,
  });

  const handleToggleFullScreen = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      toggleFullscreen();
    },
    [toggleFullscreen],
  );

  return (
    <ButtonWithTooltip
      onClick={handleToggleFullScreen}
      title={isFullscreen ? 'Exit full screen' : 'Enter full screen'}
    >
      {isFullscreen ? <MinimizeIcon /> : <FullscreenIcon />}
    </ButtonWithTooltip>
  );
}
