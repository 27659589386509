import { Button } from '@/components/ui/button';
import { AssigneeSelector } from '@/pages/Issues/AssigneeSelector';
import { getSeverityDisplay } from '@/pages/Issues/Issues.tsx';
import { NewIssue } from '@/pages/Issues/NewIssue';
import { StatusSelector } from '@/pages/Issues/StatusSelector';
import { Issue, ISSUE_STATUSES, ISSUE_STATUSES_NAMES } from '@/types/api/response/issues';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import {
  getIssuesQuery,
  useInvalidateIssuesQuery,
  useUpdateIssueMutation,
} from '@/v2/queries/issues';
import { getUsersQuery } from '@/v2/queries/users';
import { formatTimestamp } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Filter } from 'lucide-react';
import { parseAsArrayOf, parseAsString, useQueryState } from 'nuqs';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useNavigate } from 'react-router-dom';

const Issues = () => {
  const [page, setPage] = useState(1);
  const [isNewIssueOpen, setIsNewIssueOpen] = useState(false);
  const [severity, setSeverity] = useQueryState(
    'severity',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [status, setStatus] = useQueryState(
    'status',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [assignedTo, setAssignedTo] = useQueryState('assigned_to', parseAsString.withDefault(''));
  const [createdAtFilterOperator, setCreatedAtFilterOperator] = useQueryState(
    'created_at_filter_operator',
    parseAsString.withDefault('greater_than'),
  );
  const [createdAtFilterValue, setCreatedAtFilterValue] = useQueryState(
    'created_at_filter_value',
    parseAsString.withDefault(''),
  );
  const [affectedResource, setAffectedResource] = useState<any>(null);
  const [affectedResourceOperator, setAffectedResourceOperator] = useState<any>(null);
  const navigate = useNavigate();

  const { data, error, isPending } = useQuery(
    getIssuesQuery({
      page,
      severity: severity.join(','),
      status: status.join(','),
      assigned_to: assignedTo,
      affected_resource_id: affectedResource?.id,
      affected_resource_type: affectedResourceOperator,
      created_at_filter_operator: createdAtFilterOperator,
      created_at: createdAtFilterValue,
    }),
  );
  const { mutate: updateIssue } = useUpdateIssueMutation();
  const invalidateIssuesQuery = useInvalidateIssuesQuery();
  const { data: users } = useQuery(getUsersQuery());

  type IssueStatus = keyof typeof ISSUE_STATUSES;

  const handleStatusChange = (issue: Issue, status: IssueStatus) => {
    updateIssue({
      issueId: issue.issue_number,
      updates: {
        ...issue,
        status,
      },
    });
  };

  const getStatusDisplay = (issue: Issue) => (
    <StatusSelector
      currentStatus={issue.status}
      onStatusChange={(status) => handleStatusChange(issue, status as IssueStatus)}
      triggerClassName="status-popover flex justify-start"
    />
  );

  const getAffectedResourceDisplay = (issue: Issue) => {
    switch (issue.affected_resource_model_type) {
      case 'IPAddressV4':
        return (
          <LinkWithAction
            to={`/ipaddress/${(issue.affected_resource as { ipaddress?: string })?.ipaddress ?? '-'}?from=issues`}
            dataClickBypass={true}
            title="View IP Address"
            target="_self"
            actions={[
              {
                label: 'Set as Affected Resource',
                onClick: () => {
                  setAffectedResource(issue.affected_resource);
                  setAffectedResourceOperator(issue.affected_resource_model_type);
                },
                icon: <Filter />,
              },
            ]}
          >
            {(issue.affected_resource as { ipaddress?: string })?.ipaddress ?? '-'}
          </LinkWithAction>
        );
      case 'HTTPAsset':
        return (
          <LinkWithAction
            to={`/assets/${issue.affected_resource_object_id}?from=issues`}
            dataClickBypass={true}
            title="View Asset"
            target="_self"
            actions={[
              {
                label: 'Set as Affected Resource',
                onClick: () => {
                  setAffectedResource(issue.affected_resource);
                  setAffectedResourceOperator(issue.affected_resource_model_type);
                },
                icon: <Filter />,
              },
            ]}
          >
            {(issue.affected_resource as { hostname?: string })?.hostname ?? '-'}
          </LinkWithAction>
        );
      case 'Domains':
        return (
          <LinkWithAction
            to={`/assets/domains?domain_search_value=${(issue.affected_resource as { domainname?: string })?.domainname}`}
            dataClickBypass={true}
            title="View Domain"
            target="_self"
            actions={[
              {
                label: 'Set as Affected Resource',
                onClick: () => {
                  setAffectedResource(issue.affected_resource);
                  setAffectedResourceOperator(issue.affected_resource_model_type);
                },
                icon: <Filter />,
              },
            ]}
          >
            {(issue.affected_resource as { domainname?: string })?.domainname ?? '-'}
          </LinkWithAction>
        );
      default:
        return '-';
    }
  };

  const handleUpdate = (issue: Issue, updates: Partial<Issue>) => {
    updateIssue({
      issueId: issue.issue_number,
      updates: {
        ...issue,
        ...updates,
      },
    });
  };

  const columnHelper = createColumnHelper<Issue>();

  const columns: ColumnDef<Issue>[] = [
    columnHelper.accessor('severity', {
      header: 'Severity',
      cell: ({ row }) =>
        getSeverityDisplay({
          severity: row.original.severity,
          showLabel: false,
          showIcon: true,
        }),
    }),
    columnHelper.accessor('title', {
      header: 'Title',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: ({ row }) => getStatusDisplay(row.original),
    }),
    columnHelper.accessor((row) => row.affected_resource_object_id, {
      header: 'Affected Resource',
      cell: ({ row }) => getAffectedResourceDisplay(row.original),
    }),
    columnHelper.accessor('assigned_to.user.username', {
      header: 'Assigned To',
      cell: ({ row }) => (
        <AssigneeSelector
          users={users?.entries ?? []}
          assigneeId={row.original.assigned_to?.user?.id}
          assigneeName={row.original.assigned_to?.user?.username}
          onAssigneeChange={(userId) => handleUpdate(row.original, { assigned_to_id: userId })}
          data-click-bypass="true"
        />
      ),
    }),
    columnHelper.accessor('issue_number', {
      header: 'ID',
    }),
    columnHelper.accessor('created_at', {
      header: 'Created At',
      cell: ({ row }) =>
        formatTimestamp(row.original.created_at, {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
    }),
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleRowClick = (row: Issue) => {
    navigate(`/issues/${row.issue_number}`);
  };

  const onSubmit = async () => {
    invalidateIssuesQuery();
    setIsNewIssueOpen(false);
  };

  const filters: AvailableFilter[] = [
    {
      label: 'Severity',
      key: 'severity',
      type: 'multiSelect',
      placeholder: 'Severity',
      valueType: 'severity',
      options: [
        { label: 'All', value: null },
        { label: 'Low', value: 'low' },
        { label: 'Medium', value: 'medium' },
        { label: 'High', value: 'high' },
        { label: 'Critical', value: 'critical' },
        { label: 'Informational', value: 'informational' },
      ],
      state: severity,
      setState: setSeverity as (value: string | string[] | DateRange | string | null) => void,
    },
    {
      label: 'Status',
      key: 'status',
      type: 'multiSelect',
      placeholder: 'Status',
      valueType: 'status',
      options: [
        ...Object.entries(ISSUE_STATUSES_NAMES).map(([key, value]) => ({
          label: value,
          value: key,
        })),
      ],
      state: status,
      setState: setStatus as (value: string | string[] | DateRange | string | null) => void,
    },
    {
      label: 'Affected Resource',
      key: 'affected_resource',
      type: 'issues-affected-resources',
      placeholder: 'Affected Resource',
      state: affectedResource,
      setState: (value: any | null) => setAffectedResource(value),
      valueType: affectedResourceOperator,
      setValueType: (value: string) => setAffectedResourceOperator(value),
      onClear: () => {
        setAffectedResource(null);
        setAffectedResourceOperator(null);
      },
    },
    {
      label: 'Assigned To',
      key: 'assigned_to',
      type: 'select',
      placeholder: 'Assigned To',
      valueType: 'assigned_to',
      options: [
        { label: 'All', value: null },
        { label: 'Unassigned', value: 'unassigned' },
        ...(users?.entries ?? []).map((user) => ({
          label: user.user.username,
          value: user.user.id.toString(),
        })),
      ],
      state: assignedTo ? assignedTo.toString() : null,
      setState: (value: string) => setAssignedTo(value ? value.toString() : null),
    },
    {
      label: 'Created At',
      key: 'created_at',
      type: 'text-with-operator',
      placeholder: '1d or 1h',
      state: createdAtFilterValue,
      setState: setCreatedAtFilterValue as (
        value: string | string[] | DateRange | string | null,
      ) => void,
      valueType: createdAtFilterOperator,
      setValueType: setCreatedAtFilterOperator as (value: string) => void,
      options: [
        {
          label: 'Newer Than',
          value: 'greater_than',
        },
        {
          label: 'Older Than',
          value: 'less_than',
        },
      ],
    },
  ];

  const clearAllFilters = () => {
    setSeverity(null);
    setStatus(null);
    setAffectedResource(null);
    setAffectedResourceOperator(null);
    setAssignedTo(null);
    setCreatedAtFilterValue(null);
    setCreatedAtFilterOperator(null);
  };

  return (
    <div className="p-4 max-w-[2000px] mx-auto">
      <div className="flex mb-4">
        <Button onClick={() => setIsNewIssueOpen(true)} className="bg-v2-orange text-white">
          New Issue
        </Button>
      </div>
      <UniversalFilter filters={filters} clearAllFilters={clearAllFilters} className="mb-4" />
      <DataTable
        data={data?.entries ?? []}
        columns={columns}
        currentPage={parseInt(data?.page_number ?? '1')}
        totalPages={data?.total_pages ?? 1}
        totalEntries={data?.total_count ?? 0}
        onPageChange={handlePageChange}
        error={error}
        loading={isPending}
        maxWidth="w-full"
        onRowClick={handleRowClick}
        tableHeight="calc(100vh - 270px)"
      />
      <NewIssue
        users={users?.entries ?? []}
        isOpen={isNewIssueOpen}
        onOpenChange={setIsNewIssueOpen}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default Issues;
