import { useCallback, useRef } from 'react';
import { cn } from '@/lib/utils';
import { useFeatureFlag } from '@/context/FeatureFlagContext';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ChangeCodeMirrorLanguage,
  codeBlockPlugin,
  codeMirrorPlugin,
  CodeToggle,
  ConditionalContents,
  CreateLink,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  headingsPlugin,
  imagePlugin,
  InsertCodeBlock,
  InsertImage,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin,
  Separator,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from '@mdxeditor/editor';
import { oneDark } from '@codemirror/theme-one-dark';
import { ImageDialog } from './editor/ImageDialog';
import { RemoveCodeBlockButton } from './editor/RemoveCodeBlockButton';
import { FullScreenToggle } from './editor/FullScreenToggle';
import { CODE_BLOCK_LANGUAGES } from './constants';
import { useFullscreen } from '@/hooks/useFullscreen';

export default function MarkdownEditor({
  markdown,
  className,
  placeholder,
  onChange,
  onBlur,
  autoFocus,
  imageUploadHandler,
  noRoundedBorder,
  embedded,
  disallowFullScreen,
}: {
  markdown: string;
  placeholder?: string;
  className?: string;
  onChange: (markdown: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
  autoFocus?: boolean;
  imageUploadHandler?: (file: File) => Promise<string>;
  noRoundedBorder?: boolean;
  embedded?: boolean;
  disallowFullScreen?: boolean;
}) {
  const originalSnapshot = useRef(markdown);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isFullscreen } = useFullscreen({ element: containerRef.current });

  const isV2MarkdownEditorEnabled = useFeatureFlag('V2_MARKDOWN_EDITOR');

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLElement>) => {
      if (e.currentTarget.contains(e.relatedTarget as Node)) {
        return;
      }

      onBlur?.(e);
    },
    [onBlur],
  );

  const buildPlugins = useCallback(
    () => [
      toolbarPlugin({
        toolbarContents: () => (
          <>
            <DiffSourceToggleWrapper>
              <ConditionalContents
                options={[
                  {
                    when: (editor) => editor?.editorType === 'codeblock',
                    contents: () => (
                      <>
                        <ChangeCodeMirrorLanguage />
                        <Separator />
                        <RemoveCodeBlockButton />
                      </>
                    ),
                  },
                  {
                    fallback: () => (
                      <>
                        <BlockTypeSelect />
                        <BoldItalicUnderlineToggles />
                        <Separator />
                        <ListsToggle />
                        <Separator />
                        <InsertImage />
                        <CreateLink />
                        <Separator />
                        <CodeToggle />
                        <InsertCodeBlock />
                      </>
                    ),
                  },
                ]}
              />
            </DiffSourceToggleWrapper>
            {!disallowFullScreen && (
              <>
                <Separator />
                <FullScreenToggle />
              </>
            )}
          </>
        ),
      }),
      headingsPlugin(),
      listsPlugin(),
      tablePlugin(),
      quotePlugin(),
      thematicBreakPlugin(),
      imagePlugin({
        imageUploadHandler,
        ImageDialog,
      }),
      linkPlugin(),
      linkDialogPlugin(),
      codeBlockPlugin({ defaultCodeBlockLanguage: 'text' }),
      codeMirrorPlugin({
        codeMirrorExtensions: [oneDark],
        autoLoadLanguageSupport: true,
        codeBlockLanguages: CODE_BLOCK_LANGUAGES,
      }),
      diffSourcePlugin({ diffMarkdown: originalSnapshot.current, viewMode: 'rich-text' }),
      markdownShortcutPlugin(), // Keep this last
    ],
    [originalSnapshot, imageUploadHandler],
  );

  return (
    <div
      ref={containerRef}
      className={cn(
        'markdown-editor',
        className,
        (noRoundedBorder || embedded) && 'has-no-rounded-borders',
        embedded && !isFullscreen && 'embedded',
        isFullscreen && 'fullscreen',
      )}
      tabIndex={-1}
      onBlur={handleBlur}
    >
      {isV2MarkdownEditorEnabled === null ? (
        <div className="flex items-center justify-center h-[100px]">
          <div className="w-8 h-8 border-2 border-t-slate-500 border-r-slate-500 border-b-slate-500 border-l-transparent rounded-[50%] animate-[spin_1s_linear_infinite]" />
        </div>
      ) : isV2MarkdownEditorEnabled ? (
        <MDXEditor
          className={cn(
            'w-full',
            !isFullscreen && 'h-full',
            !embedded && 'overflow-y-auto shadow-sm border border-input',
            !noRoundedBorder && !embedded && 'rounded-md',
          )}
          contentEditableClassName={cn('markdown-editor-content', embedded && 'overflow-y-auto')}
          markdown={markdown}
          plugins={buildPlugins()}
          placeholder={<span>{placeholder}</span>}
          onChange={onChange}
          autoFocus={autoFocus}
        />
      ) : (
        <textarea
          className={cn(
            'w-full h-full appearance-none outline-none text-sm border border-input p-2 shadow-sm',
            !noRoundedBorder && !embedded && 'rounded-md',
          )}
          value={markdown}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
      )}
    </div>
  );
}
