import { Input } from '@/components/ui/input';
import { useEffect, useRef } from 'react';
import { AvailableFilter } from '../UniversalFilter/UniversalFilter';

interface NormalTextFilterProps {
  filter: AvailableFilter;
  onUpdate: (filter: AvailableFilter, value: string) => void;
}

export default function NormalTextFilter({ filter, onUpdate }: NormalTextFilterProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Input
      placeholder={filter.placeholder}
      className="min-w-[200px] w-full"
      value={filter.state as string}
      type="text"
      onChange={(e) => onUpdate(filter, e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          filter.onSearch?.();
        }
      }}
      showClearButton={!!(filter.state as string)}
      onClear={() => onUpdate(filter, '')}
      ref={inputRef}
    />
  );
}
