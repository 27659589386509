import Cookies from 'js-cookie';
import { cookieNames } from '../constants';

const baseApiUrl = import.meta.env.VITE_REACT_APP_API_URL;

interface Note {
  id: string;
  text: string;
  http_asset?: string;
}

interface Alert {
  id: string;
  description: string;
  severity: string;
  title: string;
  resolved: boolean;
}

interface AssetSuggestion {
  id: string;
  name: string;
}

export const updateNote = async (noteId: string, noteText: string): Promise<void> => {
  try {
    const CSRFToken = Cookies.get(cookieNames.csrfToken);
    const response = await fetch(`${baseApiUrl}/usernotes/${noteId}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': CSRFToken || '',
      },
      body: JSON.stringify({ text: noteText }),
    });

    if (!response.ok) {
      throw new Error(`Error updating note: Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating note:', error);
    throw error;
  }
};

export const deleteNote = async (noteId: string): Promise<void> => {
  try {
    const CSRFToken = Cookies.get(cookieNames.csrfToken);
    const response = await fetch(`${baseApiUrl}/usernotes/${noteId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': CSRFToken || '',
      },
    });

    if (!response.ok) {
      throw new Error(`Error deleting note: Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error deleting note:', error);
    throw error;
  }
};

interface FetchNotesOptions {
  rowId?: string;
  search?: string;
  selectedFilters?: string[];
  verbose?: boolean;
}

export const fetchNotesData = async ({
  rowId,
  search = '',
  selectedFilters = [],
  verbose = false,
}: FetchNotesOptions): Promise<Note[]> => {
  try {
    const url = new URL(`${baseApiUrl}/usernotes`);
    const params = new URLSearchParams();

    if (rowId) {
      params.append('http_asset_id', rowId);
    }
    if (verbose) {
      params.append('verbose', verbose.toString());
    }
    if (search) {
      params.append('search', search);
    }
    if (selectedFilters.length > 0) {
      params.append('filter', selectedFilters.join(','));
    }

    url.search = params.toString();

    const response = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed getting notes');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching notes:', error);
    throw error;
  }
};

export const fetchAllNotes = async (): Promise<Note[]> => {
  try {
    const response = await fetch(`${baseApiUrl}/usernotes`, {
      method: 'GET',
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed getting notes');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching notes:', error);
    throw error;
  }
};

export const createNewNote = async (
  httpAssetId: string | null,
  noteText: string,
): Promise<Note> => {
  try {
    const CSRFToken = Cookies.get(cookieNames.csrfToken);
    const response = await fetch(`${baseApiUrl}/usernotes`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': CSRFToken || '',
      },
      body: JSON.stringify({ text: noteText, http_asset: httpAssetId }),
    });

    if (!response.ok) {
      throw new Error('Failed creating note');
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating note:', error);
    throw error;
  }
};

export const getAssetSuggestions = async (
  startingWith: string,
  filterType: 'startswith' | 'contains' = 'startswith',
): Promise<AssetSuggestion[]> => {
  try {
    let url: string;
    if (filterType === 'startswith') {
      url = `${baseApiUrl}/assets/search?startswith=${startingWith}`;
    } else if (filterType === 'contains') {
      url = `${baseApiUrl}/assets/search?contains=${startingWith}`;
    } else {
      throw new Error('Invalid filter type');
    }

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed getting asset suggestions');
    }

    return await response.json();
  } catch (error) {
    console.error('Error searching assets:', error);
    throw error;
  }
};

export const createAlertFromNote = async (
  noteId: string,
  severity: string,
  title: string,
): Promise<Alert> => {
  try {
    const CSRFToken = Cookies.get(cookieNames.csrfToken);
    const response = await fetch(`${baseApiUrl}/alerts`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': CSRFToken || '',
      },
      body: JSON.stringify({
        description: noteId,
        severity,
        title,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error creating alert: Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating alert:', error);
    throw error;
  }
};

interface GetAlertsOptions {
  filter?: string;
  page?: number;
}

export const getAlerts = async ({ filter = '', page = 1 }: GetAlertsOptions = {}): Promise<
  Alert[]
> => {
  try {
    const response = await fetch(`${baseApiUrl}/alerts?resolved=${filter}&page=${page}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error getting alerts: Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error getting alerts:', error);
    throw error;
  }
};

export const updateAlertStatus = async (
  alertId: string,
  newStatus: 'Yes' | 'No',
): Promise<Alert> => {
  try {
    const CSRFToken = Cookies.get(cookieNames.csrfToken);
    const response = await fetch(`${baseApiUrl}/alerts/${alertId}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': CSRFToken || '',
      },
      body: JSON.stringify({ resolved: newStatus === 'Yes' }),
    });

    if (!response.ok) {
      throw new Error(`Error updating alert: Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating alert status:', error);
    throw error;
  }
};
